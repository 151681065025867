import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const classes = {
    container: {
        mt: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}

export const WipPage = ({ message }) => {
    return (
        <Container>
            <Paper sx={classes.container} elevation={2}>
                <Box sx={{ m: 4, display: 'flex', borderRadius: '3px', p: '1px', boxShadow: '2px 2px 2px 1px #121212', border: '1px #373737 solid' }} >
                    <img width={500} src={'/i/logo-reactionairy.png'} alt={'Reactionairy Logo'} />
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography sx={{ typography: 'h3', m: 5 }}>{message}</Typography>
                </Box>
                <Box sx={{ m: 4 }}>
                    <img width={500} src='/i/wip.jpg' alt='Page Under Construction' />
                </Box>
            </Paper>
        </Container>
    );
}