import { useCallback, useEffect } from 'react';
import * as authStore from '../../r/authStore';
import * as recordStore from '../../r/recordStore';
import * as tmdbStore from '../../r/tmdbStore';
import * as patreonStore from '../../r/patreonStore';
import * as constants from '../../r/constants';
import { useSelector, useDispatch } from 'react-redux';


export const DataLoader = () => {

    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));

    const getAllUserLibraryMovies = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryUserLibraryMetadataMaster)), [dispatch]);
    const getAllMetadataMovies = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryMetadataMovie)), [dispatch]);
    const getAllMetadataMovieEditions = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryMetadataMovieEdition)), [dispatch]);
    const getAllReactionLibraryMetadataMasters = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster)), [dispatch]);
    const getAllReactionLibraryMetadataMedias = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia)), [dispatch]);
    const getTmdbConfiguration = useCallback(() => dispatch(tmdbStore.actionCreators.getTmdbConfiguration()), [dispatch]);
    const getPatreonUserData = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryPatreonUserData)), [dispatch]);
    const getPatreonCampaignData = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryPatreonCampaignData)), [dispatch]);
    const getPatreonTierData = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryPatreonTierData)), [dispatch]);
    const getReactors = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryReactorExpandedDto)), [dispatch]);
    const getUserReactors = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryUserReactor)), [dispatch]);
    const getUserWatchStatuses = useCallback(() => dispatch(recordStore.actionCreators.getAllRecords(constants.rnryRecordTypes.rnryUserLibraryReactionWatch)), [dispatch]);

    const getMembershipCreatorRecords = useCallback(() => dispatch(patreonStore.actionCreators.getMembershipCreatorRecords()), [dispatch]);

    const isLoadedPatreonUserData = useSelector(state => recordStore.selectors.selectIsLoaded(state, constants.rnryRecordTypes.rnryPatreonUserData));
    const isLoadedPatreonCampaignData = useSelector(state => recordStore.selectors.selectIsLoaded(state, constants.rnryRecordTypes.rnryPatreonCampaignData));
    const isLoadedPatreonTierData = useSelector(state => recordStore.selectors.selectIsLoaded(state, constants.rnryRecordTypes.rnryPatreonTierData));

    useEffect(() => {
        if (isLoggedIn) {
            getAllMetadataMovies();
            getAllMetadataMovieEditions();
            getAllReactionLibraryMetadataMasters();
            getAllReactionLibraryMetadataMedias();
            getAllUserLibraryMovies();
            getTmdbConfiguration();
            getPatreonUserData();
            getPatreonCampaignData();
            getPatreonTierData();
            getReactors();
            getUserReactors();
            getUserWatchStatuses();
        }
    }, [isLoggedIn, getUserWatchStatuses, getUserReactors, getReactors, getTmdbConfiguration, getPatreonUserData, getPatreonCampaignData, getPatreonTierData, getAllMetadataMovies, getAllMetadataMovieEditions, getAllReactionLibraryMetadataMasters, getAllReactionLibraryMetadataMedias, getAllUserLibraryMovies]);

    useEffect(() => {
        if (isLoggedIn && isLoadedPatreonUserData && isLoadedPatreonCampaignData && isLoadedPatreonTierData) {
            getMembershipCreatorRecords();
        }
    }, [getMembershipCreatorRecords, isLoadedPatreonCampaignData, isLoadedPatreonTierData, isLoadedPatreonUserData, isLoggedIn]);

    return [];
};