import { createSelector } from 'reselect';
import * as constants from '../../r/constants';
import * as authStore from '../authStore';

const selectMetadataMovieRecords = state => state.recordStore[constants.rnryRecordTypes.rnryMetadataMovie].records;
const selectMetadataMovieEditionRecords = state => state.recordStore[constants.rnryRecordTypes.rnryMetadataMovieEdition].records;
const selectUserLibraryMetadataMasterRecords = state => state.recordStore[constants.rnryRecordTypes.rnryUserLibraryMetadataMaster].records;
const selectReactorRecordsInternal = state => state.recordStore[constants.rnryRecordTypes.rnryReactorExpandedDto].records;
const selectReactionLibraryMetadataMasterRecords = state => state.recordStore[constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster].records;
const selectReactionLibraryMetadataMediaRecords = state => state.recordStore[constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia].records;
const selectUserWatchStatuses = state => state.recordStore[constants.rnryRecordTypes.rnryUserLibraryReactionWatch].records;
const selectIsConfirmProfileOpen = state => state.rnryStore.isConfirmProfileOpen;
const selectPatreonUserDataRecords = state => state.recordStore[constants.rnryRecordTypes.rnryPatreonUserData].records;
const selectReactorRnryUserIdToManage = state => state.rnryStore.reactorRnryUserIdToManage;

const selectReactorRecords = createSelector(
    [
        selectReactorRecordsInternal
    ],
    records => records.map(x => {
        let name = x.reactorNamePatreon;
        if (!name || name.trim() === '') {
            name = '<UNKNOWN>';
        }
        return { ...x, reactorNamePatreon: name }
    })
);

const selectPatreonUserData = createSelector(
    [
        selectPatreonUserDataRecords
    ],
    records => records[0]
);

const selectReactorsWithTnsUserId = createSelector(
    [
        selectReactorRecords
    ],
    (records) => {
        return records.filter(x => x.rnryUserRecord && x.rnryUserRecord.hasTnsAuthAccount);
    }
);

const selectReactorRecordForUserToManage = createSelector(
    [
        selectReactorRecords,
        selectReactorRnryUserIdToManage
    ],
    (records, userId) => {
        return records.find(x => x.rnryUserRecord && x.rnryUserRecord.id === userId);
    }
);

const selectMetadataMoviesWithMedia = createSelector(
    [
        selectMetadataMovieRecords,
        selectMetadataMovieEditionRecords
    ],
    (metadataRecords, mediaRecords) => metadataRecords.map(mr => { return { ...mr, editions: mediaRecords.filter(rec => rec.rnryMetadataMovieId === mr.id) } })
);

const selectMetadataMoviesWithMediaForMetadataMasterId = createSelector(
    [
        selectMetadataMoviesWithMedia,
        (_state, metadataMasterId) => metadataMasterId
    ],
    (records, metadataMasterId) => records.find(x => x.id === metadataMasterId)
);

const selectLibraryExpandedMovies = createSelector(
    [
        selectMetadataMovieRecords,
        selectMetadataMovieEditionRecords,
        (state, rnryLibraryType) => {
            switch (rnryLibraryType) {
                case constants.rnryLibraryTypes.reaction:
                    return selectReactionLibraryMetadataMasterRecords(state);

                case constants.rnryLibraryTypes.user:
                    return selectUserLibraryMetadataMasterRecords(state);

                default:
                    throw new Error('Invalid rnryLibraryType');
            }
        }
    ],
    (metadataMasterRecords, metadataEditionRecords, libraryRecords) => libraryRecords.filter(lib => lib.rnryMetadataMasterType === constants.rnryMetadataTypes.movie).map(lib => {
        const mmr = metadataMasterRecords.find(rec => rec.id === lib.rnryMetadataMasterId);
        const mers = mmr && metadataEditionRecords.filter(rec => rec.rnryMetadataMovieId === mmr.id);
        return {
            ...lib, metadata: { ...mmr, editions: mers }
        };
    })
);

const selectLibraryExpandedMoviesForRnryUserId = createSelector(
    [
        selectLibraryExpandedMovies,
        (_state, _rnryLibraryType, rnryUserId) => rnryUserId
    ],
    (records, rnryUserId) => {
        return records.filter(x => x.rnryUserId === rnryUserId);
    }
);

const selectReactionLibraryMoviesWithMedia = createSelector(
    [
        state => selectLibraryExpandedMovies(state, constants.rnryLibraryTypes.reaction),
        selectReactionLibraryMetadataMediaRecords
    ],
    (libraryRecords, mediaRecords) => {
        return libraryRecords.map(lib => {
            return {
                ...lib, rnryReactionMedias: mediaRecords.filter(med => med.rnryReactionLibraryMetadataMasterId === lib.id)
            };
        });
    }
);

const selectReactorReactionMoviesWithMediaForRnryUserId = createSelector(
    [
        selectReactionLibraryMoviesWithMedia,
        (_state, rnryUserId) => rnryUserId
    ],
    (records, rnryUserId) => {
        return records.filter(x => x.rnryUserId === rnryUserId);
    }
);

const selectReactorReactionMoviesWithMediaForRnryMetadataMasterId = createSelector(
    [
        selectReactionLibraryMoviesWithMedia,
        (_state, rnryMetadataMasterId) => rnryMetadataMasterId,
    ],
    (records, rnryMetadataMasterId) => {
        return records.filter(x => x.rnryMetadataMasterId === rnryMetadataMasterId);
    }
);

// TODO: For rnryUserLibraryReactionWatch, go ahead and remove both rnryUserLibraryMetadataMasterId and rnryReactionLibraryMetadataMediaIdHint
// Matching is done based on combination of user and metadata ids, so those two ids will get out of sync if library records are deleted/readded
// No real reason to use the ids directly?

const selectReactorReactionMoviesWithMediaAndWatchStatusForTnsUserId = createSelector(
    [
        selectReactorReactionMoviesWithMediaForRnryUserId,
        selectUserWatchStatuses,
        selectMetadataMovieRecords,
        selectMetadataMovieEditionRecords,
    ],
    (records, userWatchStatuses, metadataMasterRecords, metadataEditionRecords) => {
        const reactionsWithWatchStatus = records.map(reaction => {
            const mmr = metadataMasterRecords.find(rec => rec.id === reaction.rnryMetadataMasterId);
            const mers = mmr && metadataEditionRecords.filter(rec => rec.rnryMetadataMovieId === mmr.id);
            const medias = reaction.rnryReactionMedias.map(media => {
                const watch = userWatchStatuses.find(w => w.rnryMetadataMediaId === media.rnryMetadataMediaId && w.rnryReactionType === media.rnryReactionType);
                return { ...media, watchStatusRecord: watch };
            });
            return { ...reaction, metadata: { ...mmr, editions: mers }, rnryReactionMedias: medias };
        });
        return reactionsWithWatchStatus;
        //return records ?? { isLoaded: false, isError: false, errorMessage: false, records: [] };
    }
);



const selectReactionLibraryMoviesWithMediaForMetadataMasterId = createSelector(
    [
        selectReactionLibraryMoviesWithMedia,
        selectReactorRecords,
        (_state, metadataMasterId) => metadataMasterId
    ],
    (libraryRecords, reactors, metadataMasterId, tnsUserId) => {
        const movies = libraryRecords.filter(lib => lib.rnryMetadataMasterId === metadataMasterId);
        const moviesWithReactor = movies.map(movie => {
            const reactor = reactors.find(reactor => reactor.rnryUserRecord.id === movie.rnryUserId);
            return { ...movie, reactorRecord: reactor };
        });
        return moviesWithReactor;
    }
);

const selectReactionLibraryMoviesWithMediaAndWatchStatusForMetadataMasterIdAndTnsUserId = createSelector(
    [
        selectReactionLibraryMoviesWithMediaForMetadataMasterId,
        selectUserWatchStatuses,
        authStore.selectors.selectTnsUserId
    ],
    (reactionRecords, userWatchStatuses, tnsUserId) => {
        const reactionsWithWatchStatus = reactionRecords.map(reaction => {
            const medias = reaction.rnryReactionMedias.map(media => {
                const watch = userWatchStatuses.find(w => w.tnsUserId === tnsUserId && w.rnryMetadataMediaId === media.rnryMetadataMediaId && w.rnryReactionType === media.rnryReactionType && w.reactorRnryUserId === media.rnryUserId);
                return { ...media, watchStatusRecord: watch };
            });
            return { ...reaction, rnryReactionMedias: medias };
        });
        return reactionsWithWatchStatus;
    }
);

const selectReactionLibraryMetadataMediaRecordsForMetadataMasterId = createSelector(
    [
        selectReactionLibraryMetadataMediaRecords,
        (_state, metadataMasterId) => metadataMasterId
    ],
    (mediaRecords, metadataMasterId) => mediaRecords.filter(x => x.rnryMetadataMasterId === metadataMasterId)
);

const selectReactionLibraryMetadataMediaRecordsForMetadataMasterIdAndRnryUserId = createSelector(
    [
        selectReactionLibraryMetadataMediaRecordsForMetadataMasterId,
        (_state, _metadataMasterId, rnryUserId) => rnryUserId
    ],
    (mediaRecords, rnryUserId) => mediaRecords.filter(x => x.rnryUserId === rnryUserId)
);

//const selectReactionLibraryExpandedMovies = createSelector(
//    [
//        selectContentMovieRecords,
//        selectContentMovieEditionRecords,
//        selectReactionContentRecords
//    ],
//    (contentRecords, editionRecords, libraryRecords) => libraryRecords.filter(lib => lib.rnryContentType === constants.rnryContentTypes.movie).map(lib => {
//        const cr = contentRecords.find(c => c.id === lib.rnryContentId);
//        const ers = editionRecords.filter(er => er.rnryContentMovieId === cr.id);
//        return {
//            ...lib, contentData: { ...cr, editions: ers }
//        };
//    })
//);

const selectLibraryExpandedMovieByMetadataMasterId = createSelector(
    [
        (state, rnryLibraryType, _id) => selectLibraryExpandedMovies(state, rnryLibraryType),
        (_state, _rnryLibraryType, id) => id
    ],
    (libraryRecords, id) => {
        return libraryRecords.find(lib => lib.rnryMetadataMasterType === constants.rnryMetadataTypes.movie && lib.rnryMetadataMasterId === id);
    }
);

//const selectReactionLibraryExpandedMovieByContentId = createSelector(
//    [
//        selectReactionLibraryExpandedMovies,
//        (_state, id) => id
//    ],
//    (libraryRecords, id) => {
//        return libraryRecords.find(lib => lib.rnryContentType === constants.rnryContentTypes.movie && lib.rnryContentId === id);
//    }
//);

export const selectors = {
    selectMetadataMovieRecords,
    selectMetadataMovieEditionRecords,
    selectUserLibraryMetadataMasterRecords,
    selectReactionLibraryMetadataMasterRecords,
    selectReactionLibraryMetadataMediaRecords,
    selectReactionLibraryMetadataMediaRecordsForMetadataMasterId,
    selectReactionLibraryMoviesWithMediaForMetadataMasterId,
    selectIsConfirmProfileOpen,
    selectLibraryExpandedMovies,
    selectLibraryExpandedMovieByMetadataMasterId,
    selectReactionLibraryMoviesWithMedia,
    selectReactionLibraryMoviesWithMediaAndWatchStatusForMetadataMasterIdAndTnsUserId,
    selectReactorReactionMoviesWithMediaForRnryUserId,
    selectReactorReactionMoviesWithMediaAndWatchStatusForTnsUserId,
    selectMetadataMoviesWithMedia,
    selectMetadataMoviesWithMediaForMetadataMasterId,
    selectPatreonUserDataRecords,
    selectPatreonUserData,
    selectReactorReactionMoviesWithMediaForRnryMetadataMasterId,
    selectReactorRnryUserIdToManage,
    selectLibraryExpandedMoviesForRnryUserId,
    selectReactionLibraryMetadataMediaRecordsForMetadataMasterIdAndRnryUserId,
    selectReactorRecords,
    selectReactorRecordForUserToManage,
    selectReactorsWithTnsUserId
}