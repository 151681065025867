import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as auth from './';
//import * as alert from '../alert';

function* watchLoginUser() {
    yield takeEvery(actionTypes.loginUserSaga, loginUser);
}

function loginUser(action) {
    const path = window.location.pathname;
    //const query = window.location.search ?? '';
    const query = window.location.search === null ? '' : window.location.search;
    const redirectUrl = encodeURIComponent(`${path}${query}`);
    window.location.href = `/auth/login?redirect_uri=${redirectUrl}&provider_code=${action.providerCode}`;
}

function* watchLogoutUser() {
    yield takeEvery(actionTypes.logoutUserSaga, logoutUser);
}

function logoutUser() {
    const path = window.location.pathname;
    //const query = window.location.search ?? '';
    const query = window.location.search === null ? '' : window.location.search;
    const redirectUrl = encodeURIComponent(`${path}${query}`);
    window.location.href = `/auth/logout?redirect_uri=${redirectUrl}`;
}

function* watchGetAuthUserInfo() {
    yield takeEvery(actionTypes.getAuthUserInfoSaga, getAuthUserInfoAsync);
}

function* getAuthUserInfoAsync() {
    const url = '/auth/info';
    //const url = `/userapi/authprofile`;
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {

        // TODO: This call shouldn't be here, so I've replaced. 1) It is causing the sliding timeout on the cookie to extend forever and 2) we should just be surfacing subscription status along with the user info on the /auth/info call.
        //const subscriptionUrl = '/subs/stripe/status';
        //const subresult = yield axios.get(subscriptionUrl).catch(error => error);
        yield put(auth.actionCreators.receiveAuthUserInfo(result.data));
        //const rnryUserId = yield select(auth.selectors.selectRnryUserId());
        //if (!rnryUserId || rnryUserId === '') {
        //    yield put(auth.actionCreators.getRnryUser());
        //}
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

function* watchUpdateAuthUserInfo() {
    yield takeEvery(actionTypes.updateAuthUserInfoSaga, updateAuthUserInfo);
}

function* updateAuthUserInfo(action) {
    yield put(auth.actionCreators.setIsSaving(true));
    const url = `api/u/${action.record.tnsUserId}/profile`;
    const result = yield axios.patch(url, action.record).catch(error => error);
    if (result.isAxiosError) {
        //yield put(alert.actionCreators.pushAlert(`Update user info call failed with ${result.response.status}`, 'error'));
        yield put(auth.actionCreators.setIsSaving(false));
    } else {
        //yield put(alert.actionCreators.pushAlert(`Updated user info: ${action.record.id}`, 'info'));

        // TODO: This is a workaround to force cookie refresh. This call logs the user out and then hits the loginUser function again to round trip back to rnry-identity. User shouldn't be interrupted except a brief page reload.
        // Possible edge case where the rnry-identity cookie has expired?
        const url2 = '/auth/info?force_refresh=true';
        yield axios.get(url2).catch(error => error);
        yield call(loginUser, {});
        yield put(auth.actionCreators.receiveProfileInfo(action.record));
        yield put(auth.actionCreators.setIsSaving(false));
    }
}

function* watchCancelAutoRenewalSubscription() {
    yield takeEvery(actionTypes.cancelAutoRenewalSubscriptionSaga, cancelAutoRenewalSubscription);
}

function* cancelAutoRenewalSubscription() {    
    const url = '/subs/stripe/cancelautorenewal';
    const result = yield axios.post(url).catch(error => error);
    if (result.isAxiosError) {
        //yield put(alert.actionCreators.pushAlert(`Cancel auto renewal of subscription call failed with ${result.response.status}`, 'error'));        
    } else {
        //yield put(alert.actionCreators.pushAlert('Cancelled auto renewal of subscription' , 'success'));                
    }
}

export const sagaWatchers = sagaMiddleware => {
    sagaMiddleware.run(watchLoginUser);
    sagaMiddleware.run(watchLogoutUser);
    sagaMiddleware.run(watchGetAuthUserInfo);
    sagaMiddleware.run(watchUpdateAuthUserInfo);
    sagaMiddleware.run(watchCancelAutoRenewalSubscription);
}