import React, { useState, useCallback, useEffect } from 'react';
import * as rnryStore from '../../r/rnryStore';
import * as recordStore from '../../r/recordStore';
import * as authStore from '../../r/authStore';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { TmdbSearchDialog } from '../tmdbSearchDialog/TmdbSearchDialog';
import * as constants from '../../r/constants';
import { ReactionLibraryMediaContainer } from './ReactionLibraryMediaContainer';
import { SearchBoxHeader } from '../helpers/SearchBoxHeader';

const classes = {
    posterBoxList: {
        flexGrow: 1,
        p: 3,
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        backgroundColor: 'grey.800',
        overflowY: 'auto'
    },
    pageContent: {
        backgroundColor: 'grey.800',
        flexGrow: 1,
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}

export const ReactionLibraryPage = () => {

    const dispatch = useDispatch();

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const libraryRecords = useSelector(state => recordStore.selectors.selectRecordsForRnryUserId(state, constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster, rnryUserIdToManage));
    const libraryRecordsExpandedAll = useSelector(state => rnryStore.selectors.selectReactorReactionMoviesWithMediaForRnryUserId(state, rnryUserIdToManage));
    const addTmdbRecordToLibrary = useCallback(record => dispatch(rnryStore.actionCreators.addItemToLibrary(constants.rnryLibraryTypes.reaction, constants.rnryMetadataTypes.movie, record, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);
    const removeLibraryRecord = useCallback(record => dispatch(recordStore.actionCreators.deleteRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster, record)), [dispatch]);

    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const minSearchTextLength = 1;
    const [libraryRecordsExpanded, setLibraryRecordsExpanded] = useState([]);

    const applyFilter = useCallback(() => {
        if (libraryRecordsExpandedAll.length > 0 && searchText.trim().length >= minSearchTextLength) {
            // TODO: Include metadata in objects and search on linked movie title in addition to post title
            setLibraryRecordsExpanded(libraryRecordsExpandedAll.filter(x => x.metadata.displayTitle.toLowerCase().includes(searchText.trim().toLowerCase())));
        } else {
            setLibraryRecordsExpanded([...libraryRecordsExpandedAll]);
        }
    }, [libraryRecordsExpandedAll, searchText]);

    useEffect(() => {
        applyFilter();
    }, [searchText, applyFilter]);

    useEffect(() => {
        if (libraryRecordsExpandedAll) {
            setLibraryRecordsExpanded([...libraryRecordsExpandedAll]);
            applyFilter();
        }
    }, [applyFilter, libraryRecordsExpandedAll]);

    const loadReactions = useCallback(() => dispatch(rnryStore.actionCreators.getReactorReactions(rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);

    //const isLoaded = useCallback(() => {
    //    return rnryUserIdToManage && true;
    //}, [rnryUserIdToManage]);

    let ignore = false;

    useEffect(() => {
        if (!ignore && rnryUserIdToManage && rnryUserIdToManage !== '') {
            loadReactions();
        }
        return () => {
            ignore = true;
        }
    }, [rnryUserIdToManage]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 0 }}>
            <TmdbSearchDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                libraryRecords={libraryRecords}
                libraryRecordsExpanded={libraryRecordsExpandedAll}
                addTmdbRecordToLibrary={addTmdbRecordToLibrary}
                removeLibraryRecord={removeLibraryRecord}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 0, m: 3 }}>
                <Typography>{'Reaction Library'}</Typography>
                <Tooltip title={'Add Movies To Your Library'} arrow placement={'right'}>
                    <Fab sx={{ ml: 2, height: '24px', width: '24px', minHeight: '24px' }} size={'small'} onClick={() => setIsOpen(true)} color={'primary'}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Box>
            <Box sx={classes.pageContent}>
                <SearchBoxHeader sx={{ width: '100%', maxWidth: '1000px' }} searchText={searchText} setSearchText={setSearchText} minLength={minSearchTextLength} />
                <Box sx={classes.posterBoxList}>

                    {libraryRecords && libraryRecords.length > 0 && libraryRecordsExpanded.sort((a, b) => a.metadata.displayTitle > b.metadata.displayTitle ? 1 : -1).map(lib => <ReactionLibraryMediaContainer key={lib.id} libraryRecord={lib} />)}
                </Box>
            </Box>
        </Box>
    );
};