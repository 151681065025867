import React, { useCallback } from 'react';
import * as patreonStore from '../../r/patreonStore';
import * as tmdbStore from '../../r/tmdbStore';
import * as recordStore from '../../r/recordStore';
import * as rnryStore from '../../r/rnryStore';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import ExternalLinkIcon from '@mui/icons-material/Launch';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import FreeIcon from '@mui/icons-material/MoneyOff';
import * as constants from '../../r/constants';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import FirstTimeWatchedIcon from '@mui/icons-material/RepeatOne';
import RepeatWatchIcon from '@mui/icons-material/Repeat';
import FullLengthIcon from '@mui/icons-material/HourglassFull';
import EditedIcon from '@mui/icons-material/ContentCut';
import UnlinkIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/AddLink';
//import EditIcon from '@mui/icons-material/Edit';

const classes = {
    postContainer: {
        m: 2,
        display: 'flex',
        boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none',
        border: 1,
        borderColor: 'grey.800',
        transform: 'translate(0%, 0%)',
        transition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        webkitTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        mozTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        oTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        '&:hover': {
            backgroundColor: '#080808',
            //cursor: 'pointer',
            transform: 'translate(-4px, -4px)',
            transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
        },
        '&:active': {
            //cursor: 'pointer',
            transform: 'translate(-4px, -4px)',
            transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
        }
    },
    postContent: {
        mx: 2,
        my: 1,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    postTitleRow: {
        display: 'flex',
        alignItems: 'center'
    },
    postLinkRow: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    postLinkButton: {
        ml: 1
    },
    mediaBox: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    mediaBoxText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        mr: 1
    },
    mediaTitle: {
        fontSize: 'body2.fontSize',
        color: 'common.white',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    mediaReleaseYear: {
        fontSize: 'body2.fontSize',
        color: 'grey.500',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    mediaIcons: {
        mt: 2,
        display: 'flex'
    }
};

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const PatreonPost = ({ patreonPost, requestLinkToReaction }) => {

    const dispatch = useDispatch();

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const reactionMedia = useSelector(state => patreonStore.selectors.selectReactionLibraryMetadataMediaForPostId(state, patreonPost.id));
    const updateReactionMedia = useCallback(record => dispatch(recordStore.actionCreators.updateRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, record, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);

    const metadataMasterSelector = useCallback(() => {
        if (reactionMedia) {
            return state => recordStore.selectors.selectRecordById(state, constants.rnryRecordTypes.rnryMetadataMovie, reactionMedia.rnryMetadataMasterId);
        } else {
            return () => null;
        }
    }, [reactionMedia]);
    const metadataMaster = useSelector(metadataMasterSelector());

    const tmdbConfiguration = useSelector(state => tmdbStore.selectors.selectTmdbConfiguration(state));

    const getImage = useCallback(() => {
        let src = '/i/no-image.jpg';
        let alt = 'Missing';
        if (tmdbConfiguration.images) {
            src = `${tmdbConfiguration.images.secure_base_url}${tmdbConfiguration.images.poster_sizes[4]}${metadataMaster.tmdbPosterPath}`;
            alt = metadataMaster.displayTitle;
        }
        return <img width={80} height={120} src={src} alt={alt} loading={'lazy'} />
    }, [metadataMaster, tmdbConfiguration]);

    const getIsFreeIcon = () => {
        let tooltip = 'Reaction requires paid access';
        let icon = <MoneyIcon fontSize={'large'} sx={{}} />;
        if (reactionMedia.isFree) {
            tooltip = 'Reaction is free to access';
            icon = <FreeIcon fontSize={'large'} sx={{ color: 'gray' }} />;
        }
        return (
            <Box sx={{ mr: 0 }} >
                <DelayedTooltip title={tooltip}>
                    {icon}
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsBlurredIcon = () => {
        let tooltip = 'Media content is not blurred out';
        let icon = <BlurOffIcon fontSize={'large'} sx={{ color: 'gray' }} />;
        if (reactionMedia.isBlurred) {
            tooltip = 'Media content is blurred out';
            icon = <BlurOnIcon fontSize={'large'} sx={{}} />;
        }
        return (
            <Box sx={{ mr: 1 }} >
                <DelayedTooltip title={tooltip}>
                    {icon}
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsFirstTimeWatchedIcon = () => {
        let tooltip = 'Reaction is a repeat watch';
        let icon = <RepeatWatchIcon fontSize={'large'} sx={{ color: 'gray' }} />;
        if (reactionMedia.isFirstTimeWatch) {
            tooltip = 'Reaction is a first time watch';
            icon = <FirstTimeWatchedIcon fontSize={'large'} sx={{}} />;
        }
        return (
            <Box sx={{ mr: 1 }} >
                <DelayedTooltip title={tooltip}>
                    {icon}
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsFullLengthIcon = () => {
        let tooltip = 'Reaction is edited';
        let icon = <EditedIcon fontSize={'large'} sx={{}} />;
        if (reactionMedia.rnryReactionType === constants.rnryReactionTypes.fullLength) {
            tooltip = 'Reaction is full length';
            icon = <FullLengthIcon fontSize={'large'} sx={{}} />;
        }
        if (reactionMedia.rnryReactionType === constants.rnryReactionTypes.fullLength || reactionMedia.rnryReactionType === constants.rnryReactionTypes.edited) {
            return (
                <Box sx={{ mr: 1 }} >
                    <DelayedTooltip title={tooltip}>
                        {icon}
                    </DelayedTooltip>
                </Box>
            );
        }
    };

    const handleUnlinkReaction = useCallback(() => {
        if (reactionMedia) {
            const updatedRecord = {
                ...reactionMedia,
                patreonPostId: '',
                patreonPostUrl: ''
            };
            updateReactionMedia(updatedRecord);
        }
    }, [reactionMedia, updateReactionMedia]);

    const getReactionRow = () => {
        if (reactionMedia) {
            return (
                <React.Fragment>
                    <Box sx={{ alignSelf: 'flex-end' }}>
                        <DelayedTooltip title={'Unlink reaction from Patreon post'}>
                            <Button color={'error'} onClick={handleUnlinkReaction} startIcon={<UnlinkIcon />} sx={{ mr: 1 }} variant={'outlined'} >
                                {'Unlink'}
                            </Button>
                        </DelayedTooltip>
                        {/*<DelayedTooltip title={'(NOT YET IMPLEMENTED) Edit reaction details'}>*/}
                        {/*    <span>*/}
                        {/*        <Button disabled startIcon={<EditIcon />} sx={{ mr: 1 }} variant={'outlined'} >*/}
                        {/*            {'Edit'}*/}
                        {/*        </Button>*/}
                        {/*    </span>*/}
                        {/*</DelayedTooltip>*/}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={classes.mediaBoxText} >
                        <Typography sx={classes.mediaTitle} >{metadataMaster.displayTitle}</Typography>
                        <Typography sx={classes.mediaReleaseYear}>{metadataMaster.releaseDate.substring(0, 4)}</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={classes.mediaIcons}>
                            {getIsFullLengthIcon()}
                            {getIsFirstTimeWatchedIcon()}
                            {getIsBlurredIcon()}
                            {getIsFreeIcon()}
                        </Box>
                    </Box>
                    {getImage()}
                </React.Fragment>
            );
        } else {
            return <Button disabled={reactionMedia} color={'success'} startIcon={<LinkIcon />} onClick={() => requestLinkToReaction(patreonPost)} variant={'outlined'}>{'Add Link To Reaction'}</Button>;
        }
    }

    return (patreonPost &&
        <Paper sx={classes.postContainer}>
            <Box sx={classes.postContent}>
                <Box sx={classes.postTitleRow}>
                    <Typography>{patreonPost.attributes.title}</Typography>
                    <IconButton sx={classes.postLinkButton} component={RouterLink} target={'_blank'} to={`https://www.patreon.com${patreonPost.attributes.url}`} rel={'noreferrer'}>
                        <ExternalLinkIcon fontSize={'small'} />
                    </IconButton>
                </Box>
                <Box sx={classes.postLinkRow}>
                    {getReactionRow()}
                </Box>
            </Box>
        </Paper>
    );
};