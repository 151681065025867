export const getRecordsSaga = 'GET_RECORDS_SAGA';
export const getAllRecordsSaga = 'GET_ALL_RECORDS_SAGA';
export const getRecordByIdSaga = 'GET_RECORD_BY_ID_SAGA';
export const receiveRecords = 'RECEIVE_RECORDS';
export const updateRecordSaga = 'UPDATE_RECORD_SAGA';
export const createRecordSaga = 'CREATE_RECORD_SAGA';
export const deleteRecordSaga = 'DELETE_RECORD_SAGA';
export const deprecateRecordSaga = 'DEPRECATE_RECORD_SAGA';
export const setSelectedId = 'SET_SELECTED_ID';
export const setIsSaving = 'SET_IS_SAVING';
export const beginLoadingRecords = 'BEGIN_LOADING_RECORDS';
export const setTempRecord = 'SET_TEMP_RECORD';
export const createRecordSuccess = 'CREATE_RECORD_SUCCESS';
export const createRecordSuccessCompleted = 'CREATE_RECORD_SUCCESS_COMPLETED';
export const updateRecordSuccess = 'UPDATE_RECORD_SUCCESS';
export const deleteRecordSuccess = 'DELETE_RECORD_SUCCESS';
export const deprecateRecordSuccess = 'DEPRECATE_RECORD_SUCCESS';