import * as actionTypes from './actionTypes';
import * as _ from 'lodash';

const initializeState = state => {
    if (state && state.recordTypes && state.recordTypes.every(x => state[x.recordTypeName] && state[x.recordTypeName].records)) {
        return state;
    } else if (state && state.recordTypes) {
        var output = state.recordTypes.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.recordTypeName]: {
                    records: [],
                    isLoaded: false,
                    isLoading: false,
                    isLoadError: false,
                    needsReload: false,
                    isSaving: false,
                    loadErrorMessage: '',
                    selectedId: '',
                    tempRecord: {},
                    serverIdMaps: {}
                }
            };
        }, {});
        return { recordTypes: [...state.recordTypes], ...output };
    } else {
        return {
            recordTypes: []
        };
    }
};

export const reducer = (state, action) => {
    state = initializeState(state);

    switch (action.type) {
        case actionTypes.beginLoadingRecords:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    isLoaded: false,
                    isLoading: true,
                    isLoadError: false,
                    needsReload: false,
                    loadErrorMessage: ''
                }
            };

        case actionTypes.receiveRecords:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: action.records,
                    isLoaded: true,
                    isLoading: false,
                    isLoadError: false,
                    needsReload: false,
                    loadErrorMessage: ''
                }
            };

        case actionTypes.setIsSaving:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    isSaving: action.isSaving
                }
            };

        case actionTypes.setSelectedId:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    selectedId: action.recordId
                }
            };

        case actionTypes.setTempRecord:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    tempRecord: action.tempRecord
                }
            };

        case actionTypes.createRecordSuccess:
            const existingRecords = state[action.recordType].records;
            const existingMaps = state[action.recordType].serverIdMaps;
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    serverIdMaps: { ...existingMaps, [action.tempId]: action.newRecord.id },
                    records: [...existingRecords.filter(x => x.id !== action.newRecord.id), action.newRecord]
                }
            };

        case actionTypes.updateRecordSuccess:
            const oldRecords = state[action.recordType].records;
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: [...oldRecords.filter(x => x.id !== action.updatedRecord.id), action.updatedRecord]
                }
            };

        case actionTypes.deleteRecordSuccess:
            const prevRecords = state[action.recordType].records;
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: [...prevRecords.filter(x => x.id !== action.deletedRecordId)]
                }
            };

        case actionTypes.deprecateRecordSuccess:
            const formerRecords = state[action.recordType].records;
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: [...formerRecords.filter(x => x.id !== action.deprecatedRecord.id), _.cloneDeep(action.deprecatedRecord)]
                }
            };

        default:
            return state;
    }
};
