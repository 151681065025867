export const rnryRecordTypes = {
    rnryMetadataMovie: 'rnryMetadataMovie',
    rnryMetadataMovieEdition: 'rnryMetadataMovieEdition',
    rnryMetadataShow: 'rnryMetadataShow',
    rnryMetadataSeason: 'rnryMetadataSeason',
    rnryMetadataEpisode: 'rnryMetadataEpisode',
    rnryMetadataEpisodeEdition: 'rnryMetadataEpisodeEdition',
    rnryReactionLibraryMetadataMaster: 'rnryReactionLibraryMetadataMaster',
    rnryReactionLibraryMetadataMedia: 'rnryReactionLibraryMetadataMedia',
    rnryUserLibraryMetadataMaster: 'rnryUserLibraryMetadataMaster',
    rnryUserLibraryReactionWatch: 'rnryUserLibraryReactionWatch',
    rnryPatreonUserData: 'rnryPatreonUserData',
    rnryPatreonCampaignData: 'rnryPatreonCampaignData',
    rnryPatreonTierData: 'rnryPatreonTierData',
    rnryReactorExpandedDto: 'rnryReactorExpandedDto',
    rnryUserReactor: 'rnryUserReactor'
};

export const rnryMetadataTypes = {
    movie: 'rnry_movie',
    movieEdition: 'rnry_movie_edition',
    show: 'rnry_show',
    season: 'rnry_season',
    episode: 'rnry_episode',
    episodeEdition: 'rnry_episode_edition'
};

export const rnryReactionTypes = {
    fullLength: 'full_length',
    edited: 'edited'
};

export const rnryEditionTypes = {
    standard: 'rnry_standard'
};

export const patreonMembershipStatusValues = {
    activePatron: 'active_patron',
    declinedPatron: 'declined_patron',
    formerPatron: 'former_patron'
};

export const reactionVideoHostCodes = {
    youtube: 'youtube',
    vimeo: 'vimeo',
    patreon: 'patreon',
    unknown: 'unknown'
}

export const rnryLibraryTypes = {
    user: 'user',
    reaction: 'reaction'
}

export const rnryWatchStatuses = {
    unwatched: 'unwatched',
    partiallyWatched: 'partiallyWatched',
    watched: 'watched'
}