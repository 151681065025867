import React, { useCallback, useState } from 'react';
import * as constants from '../../r/constants';
import * as recordStore from '../../r/recordStore';
import * as rnryStore from '../../r/rnryStore';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as tmdbStore from '../../r/tmdbStore';

const classes = {
    posterBoxBorder: {
        width: '360px',
        border: 2,
        borderColor: 'grey.800',
        textDecoration: 'none',
        borderRadius: '10px',
        mx: 2,
        my: 1,
        backgroundColor: 'grey.900',
        boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none'
    },
    posterBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 1
    },
    textColumn: {
        ml: 2,
        display: 'flex',
        minWidth: '100px',
        flexDirection: 'column'
    },
    posterBoxTitle: {
        fontSize: 'body2.fontSize',
        color: 'common.white',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    posterBoxYear: {
        fontSize: 'body2.fontSize',
        color: 'grey.500',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '150px',
        alignSelf: 'center'
    },
    imageColumn: {
        display: 'flex',
        alignItems: 'center'
    },
    optionsRow: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        my: '8px'
    },
    linkButton: {
        width: '130px',
        my: '4px'
    }
};

const getCleanVideoHostCode = patreonEmbedData => {
    if (patreonEmbedData && patreonEmbedData.provider) {
        switch (patreonEmbedData.provider.toLowerCase()) {
            case 'youtube':
                return constants.reactionVideoHostCodes.youtube;

            case 'vimeo':
                return constants.reactionVideoHostCodes.vimeo;

            default:
                return constants.reactionVideoHostCodes.patreon;
        }
    } else {
        return constants.reactionVideoHostCodes.patreon;
    }
};

export const ReactionLibraryLinkToPostItem = ({ libraryExpandedItem, currentRequestedPatreonPostToLink, setIsOpen }) => {

    const dispatch = useDispatch();

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));

    const [isFirstTimeWatch, setIsFirstTimeWatch] = useState(true);
    const [isBlurred, setIsBlurred] = useState(true);
    const [isFree, setIsFree] = useState(false);

    const tmdbConfiguration = useSelector(state => tmdbStore.selectors.selectTmdbConfiguration(state));
    const reactionMedias = useSelector(state => rnryStore.selectors.selectReactionLibraryMetadataMediaRecordsForMetadataMasterIdAndRnryUserId(state, libraryExpandedItem.metadata.id, rnryUserIdToManage));
    const addReactionMediaToLibrary = useCallback(record => dispatch(recordStore.actionCreators.createRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, record, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);
    const updateReactionMediaInLibrary = useCallback(record => dispatch(recordStore.actionCreators.updateRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, record, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);
    
    const linkToPatreonPost = useCallback(reactionType => {
        const cleanHostCode = getCleanVideoHostCode(currentRequestedPatreonPostToLink.attributes.embed_data);
        const standardEdition = libraryExpandedItem.metadata.editions.find(x => x.metadataMediaEditionType === constants.rnryEditionTypes.standard);
        if (!standardEdition) {
            throw new Error('Edition record is missing for selected title');
        }
        var existingRecord = reactionMedias.find(x => x.rnryReactionType === reactionType);
        if (existingRecord) {
            existingRecord.isFirstTimeWatch = isFirstTimeWatch;
            existingRecord.isFree = isFree;
            existingRecord.isBlurred = isBlurred;
            existingRecord.patreonPostId = currentRequestedPatreonPostToLink.id;
            existingRecord.patreonPostUrl = `https://www.patreon.com${currentRequestedPatreonPostToLink.attributes.url}`;
            existingRecord.reactionVideoHost = cleanHostCode;
            existingRecord.reactionVideoUrl = currentRequestedPatreonPostToLink.attributes.embed_url ?? '';
            updateReactionMediaInLibrary(existingRecord);
        }
        else {
            const newMediaRecord = {
                rnryMetadataMasterType: libraryExpandedItem.metadata.rnryMetadataType,
                rnryMetadataMasterId: libraryExpandedItem.metadata.id,
                rnryMetadataMediaType: standardEdition.rnryMetadataType,
                rnryMetadataMediaId: standardEdition.id,
                rnryReactionLibraryMetadataMasterId: libraryExpandedItem.id,
                rnryUserId: rnryUserIdToManage,
                rnryReactionType: reactionType,
                isFirstTimeWatch,
                isFree,
                isBlurred,
                patreonPostId: currentRequestedPatreonPostToLink.id,
                patreonPostUrl: `https://www.patreon.com${currentRequestedPatreonPostToLink.attributes.url}`,
                reactionVideoHost: cleanHostCode,
                reactionVideoUrl: currentRequestedPatreonPostToLink.attributes.embed_url ?? '',
                reactionStarRatingAverage: 0,
                reactionStarRatingCount: 0,
                overview: ''
            }
            addReactionMediaToLibrary(newMediaRecord);
        }
        setIsOpen(false);
    }, [rnryUserIdToManage, currentRequestedPatreonPostToLink, isBlurred, isFirstTimeWatch, isFree, libraryExpandedItem, addReactionMediaToLibrary, setIsOpen, updateReactionMediaInLibrary, reactionMedias]);

    const isButtonDisabled = useCallback(buttonReactionType => {
        return reactionMedias.some(x => x.rnryReactionType === buttonReactionType && x.patreonPostId !== '');
    }, [reactionMedias]);

    const getImage = useCallback(() => {
        let src = '/i/no-image.jpg';
        let alt = 'Missing';
        if (tmdbConfiguration.images) {
            src = `${tmdbConfiguration.images.secure_base_url}${tmdbConfiguration.images.poster_sizes[4]}${libraryExpandedItem.metadata.tmdbPosterPath}`;
            alt = libraryExpandedItem.metadata.displayTitle;
        }
        return <img width={180} height={270} src={src} alt={alt} loading={'lazy'} />
    }, [libraryExpandedItem, tmdbConfiguration]);

    return (
        <Box sx={classes.posterBoxBorder} key={libraryExpandedItem.id} to={`/reactions/${libraryExpandedItem.rnryContentId}`}>
            <Box sx={classes.posterBox}>
                <Box sx={classes.imageColumn}>
                    {getImage()}
                </Box>
                <Box sx={classes.textColumn}>
                    <Typography sx={classes.posterBoxTitle}>{libraryExpandedItem.metadata.displayTitle}</Typography>
                    <Typography sx={classes.posterBoxYear}>{libraryExpandedItem.metadata.releaseDate.substring(0, 4)}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={classes.optionsRow}>
                        <FormControlLabel slotProps={{ typography: { fontSize: '14px' } }} control={<Checkbox size={'small'} checked={isFirstTimeWatch} onChange={() => setIsFirstTimeWatch(!isFirstTimeWatch)} />} label={'1st Time Watch'} />
                        <FormControlLabel slotProps={{ typography: { fontSize: '14px' } }} control={<Checkbox size={'small'} checked={isBlurred} onChange={() => setIsBlurred(!isBlurred)} />} label={'Blurred'} />
                        <FormControlLabel slotProps={{ typography: { fontSize: '14px' } }} control={<Checkbox size={'small'} checked={isFree} onChange={() => setIsFree(!isFree)} />} label={'Free'} />
                    </Box>
                    <Box sx={classes.buttonRow}>
                        <Button sx={classes.linkButton} disabled={isButtonDisabled(constants.rnryReactionTypes.fullLength)} onClick={() => linkToPatreonPost(constants.rnryReactionTypes.fullLength)} variant={'outlined'}>{'Full Length'}</Button>
                        <Button sx={classes.linkButton} disabled={isButtonDisabled(constants.rnryReactionTypes.edited)} onClick={() => linkToPatreonPost(constants.rnryReactionTypes.edited)} variant={'outlined'}>{'Edited'}</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};