import React, { useCallback, useState, useRef } from 'react';
import * as constants from '../../r/constants';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ExternalLinkIcon from '@mui/icons-material/Launch';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ReactionMediaDetailsIconStrip } from '../reactionLibraryPage/ReactionMediaDetailsIconStrip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WatchedIcon from '@mui/icons-material/CheckCircle';
import UnwatchedIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import * as recordStore from '../../r/recordStore';
import * as authStore from '../../r/authStore';
import { Link as RouterLink } from 'react-router-dom';
/*import { ReactionMediaMiniDetailsEditor } from './ReactionMediaMiniDetailsEditor';*/
import FullLengthIcon from '@mui/icons-material/HourglassFull';
import EditedIcon from '@mui/icons-material/ContentCut';

const classes = {
    outerContainer: {
        position: 'relative',
        mt: 1
    },
    reactionDetails: {
        display: 'flex',
        alignItems: 'center',
        border: '1px black solid',
        borderRadius: '5px',
        backgroundColor: '#121212'
    },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        my: 1,
        ml: 0.5
    },
    leftColumnTopRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    leftColumnBottomRow: {
        display: 'flex',
        flexDirection: 'row',
        mt: 1,
        height: '16px',
        alignItems: 'center'
    },
    rightColumn: {
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        mr: '3px',
        my: '3px',

    },
    pseudoButton: {
        display: 'flex',
        color: 'grey.400',
        '&:hover': {
            cursor: 'pointer',
            color: 'white'
        }
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        border: '1px #707070 solid',
        borderRadius: '5px',
        backgroundColor: '#000000DD',
        justifyContent: 'flex-start'
    },
    overlayButton: {
        minWidth: 24,
        ml: '12px',
        backgroundColor: 'black',
        '&:hover': {
            backgroundColor: '#121212'
        }
    },
    addNewButtonContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    noReaction: {
        mt: 1,
        flexDirection: 'column',
        height: '73px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px #909090 solid',
        borderRadius: '5px',
        color: '#909090'
    },
    noReactionText: {
        fontSize: '0.8rem'
    },
    noReactionIcon: {
        fontSize: '12'
    }
}

const getLogo = platformCode => {
    switch (platformCode) {
        case constants.reactionVideoHostCodes.youtube:
            return <img height={16} src={'/i/youtube/yt_logo_rgb_dark.png'} alt={'YouTube'} loading={'lazy'} />;

        case constants.reactionVideoHostCodes.patreon:
            return <img width={72} src={'/i/patreon/patreon_wordmark_white.png'} alt={'Patreon'} loading={'lazy'} />;

        case constants.reactionVideoHostCodes.vimeo:
            return <img height={16} src={'/i/vimeo/vimeo_logo_blue.png'} alt={'Vimeo'} loading={'lazy'} />;

        default:
    }
};

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const ReactionMediaMiniDetailsWatcher = ({ userLibraryMovieId, libraryExpandedRecord, rnryReactionType }) => {

    const dispatch = useDispatch();

    const tnsUserId = useSelector(state => authStore.selectors.selectTnsUserId(state));

    const addWatchedRecord = useCallback(record => dispatch(recordStore.actionCreators.createRecord(constants.rnryRecordTypes.rnryUserLibraryReactionWatch, record)), [dispatch]);
    const updateWatchedRecord = useCallback(record => dispatch(recordStore.actionCreators.updateRecord(constants.rnryRecordTypes.rnryUserLibraryReactionWatch, record)), [dispatch]);

    const reactionMedia = libraryExpandedRecord.rnryReactionMedias.find(media => media.rnryReactionType === rnryReactionType);

    const isWatched = reactionMedia && reactionMedia.watchStatusRecord && reactionMedia.watchStatusRecord.watchStatus === constants.rnryWatchStatuses.watched;

    const anchorRef = useRef(null);

    const markAsUnwatchedHandler = () => {
        if (reactionMedia.watchStatusRecord) {
            const updatedRecord = {
                ...reactionMedia.watchStatusRecord,
                watchStatus: constants.rnryWatchStatuses.unwatched
            };
            updateWatchedRecord(updatedRecord);
        }
    };

    const markAsWatchedHandler = () => {
        if (reactionMedia.watchStatusRecord) {
            const updatedRecord = {
                ...reactionMedia.watchStatusRecord,
                watchStatus: constants.rnryWatchStatuses.watched
            };
            updateWatchedRecord(updatedRecord);
        } else {
            const newRecord = {
                tnsUserId: tnsUserId,
                rnryUserLibraryMetadataMasterId: userLibraryMovieId,
                reactorRnryUserId: reactionMedia.rnryUserId,
                rnryMetadataMasterType: reactionMedia.rnryMetadataMasterType,
                rnryMetadataMasterId: reactionMedia.rnryMetadataMasterId,
                rnryMetadataMediaType: reactionMedia.rnryMetadataMediaType,
                rnryMetadataMediaId: reactionMedia.rnryMetadataMediaId,
                rnryReactionType: reactionMedia.rnryReactionType,
                rnryReactionLibraryMetadataMediaIdHint: reactionMedia.id,
                watchStatus: constants.rnryWatchStatuses.watched,
                reactionStarRating: 0
            }
            addWatchedRecord(newRecord);
        }
    };

    const getWatchStatusButton = () => {
        if (!userLibraryMovieId) return [];
        let tooltipText = 'Mark as Unwatched';
        let icon = <WatchedIcon sx={{ color: 'green' }} />;
        let clickHandler = markAsUnwatchedHandler;
        if (!isWatched) {
            tooltipText = 'Mark as Watched';
            icon = <UnwatchedIcon sx={{ color: '#646464' }} />;
            clickHandler = markAsWatchedHandler;
        }
        return (
            <DelayedTooltip title={tooltipText}>
                <IconButton disabled={!userLibraryMovieId} size={'small'} onClick={clickHandler}>
                    {icon}
                </IconButton>
            </DelayedTooltip>
        );
    };

    return (reactionMedia ?
        (
            <Box sx={{ ...classes.outerContainer }} ref={anchorRef}>
                <Box sx={{ ...classes.reactionDetails }}>
                    <Box sx={classes.leftColumn}>
                        {getWatchStatusButton()}
                    </Box>
                    <Box sx={classes.leftColumn}>
                        <Box sx={classes.leftColumnTopRow}>
                            <ReactionMediaDetailsIconStrip reactionMedia={reactionMedia} />
                        </Box>
                        <Box sx={classes.leftColumnBottomRow}>
                            {getLogo(reactionMedia.reactionVideoHost)}
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={classes.rightColumn}>
                        {reactionMedia.patreonPostUrl &&
                            <Box disabled={reactionMedia.patreonPostUrl === ''} sx={classes.pseudoButton} component={RouterLink} to={reactionMedia.patreonPostUrl} target={'_blank'} rel={'noreferrer'}>
                                <ExternalLinkIcon fontSize={'small'} />
                            </Box>
                        }
                        <Box sx={{ flexGrow: 1 }} />
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box ref={anchorRef} sx={classes.noReaction}>
                {rnryReactionType === constants.rnryReactionTypes.fullLength ? <FullLengthIcon fontSize={'small'} /> : <EditedIcon fontSize={'small'} />}
                <Typography sx={classes.noReactionText}>{`No ${rnryReactionType === constants.rnryReactionTypes.fullLength ? 'full length' : 'edited'} reaction`}</Typography>
            </Box>
        )
    );

};