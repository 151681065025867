import * as actionTypes from './actionTypes';

export const actionCreators = {
    beginLoadingRecords: recordType => dispatch => {
        dispatch({ type: actionTypes.beginLoadingRecords, recordType });
    },

    getRecords: (recordType) => dispatch => {
        dispatch({ type: actionTypes.getRecordsSaga, recordType });
    },

    getAllRecords: (recordType) => dispatch => {
        dispatch({ type: actionTypes.getAllRecordsSaga, recordType });
    },

    getRecordById: (recordType, recordId) => dispatch => {
        dispatch({ type: actionTypes.getRecordByIdSaga, recordType, recordId });
    },

    receiveRecords: (recordType, records) => dispatch => {
        dispatch({ type: actionTypes.receiveRecords, recordType, records });
    },

    updateRecord: (recordType, record, useRnryUserId, rnryUserId) => dispatch => {
        dispatch({ type: actionTypes.updateRecordSaga, recordType, record, useRnryUserId, rnryUserId });
    },

    createRecord: (recordType, record, useRnryUserId, rnryUserId) => dispatch => {
        dispatch({ type: actionTypes.createRecordSaga, recordType, record, useRnryUserId, rnryUserId });
    },

    deleteRecord: (recordType, record, useRnryUserId, rnryUserId) => dispatch => {
        dispatch({ type: actionTypes.deleteRecordSaga, recordType, record, useRnryUserId, rnryUserId });
    },

    deprecateRecord: (recordType, record) => dispatch => {
        dispatch({ type: actionTypes.deprecateRecordSaga, recordType, record });
    },

    setSelectedId: (recordType, recordId) => dispatch => {
        dispatch({ type: actionTypes.setSelectedId, recordType, recordId });
    },

    setIsSaving: (recordType, isSaving) => dispatch => {
        dispatch({ type: actionTypes.setIsSaving, recordType, isSaving });
    },

    setTempRecord: (recordType, tempRecord) => dispatch => {
        dispatch({ type: actionTypes.setTempRecord, recordType, tempRecord });
    },

    createRecordSuccess: (recordType, tempId, newRecord) => dispatch => {
        dispatch({ type: actionTypes.createRecordSuccess, recordType, tempId, newRecord });
    },

    createRecordSuccessCompleted: (recordType, record) => dispatch => {
        dispatch({ type: actionTypes.createRecordSuccessCompleted, recordType, record });
    },

    updateRecordSuccess: (recordType, updatedRecord) => dispatch => {
        dispatch({ type: actionTypes.updateRecordSuccess, recordType, updatedRecord });
    },

    deleteRecordSuccess: (recordType, deletedRecordId) => dispatch => {
        dispatch({ type: actionTypes.deleteRecordSuccess, recordType, deletedRecordId });
    },

    deprecateRecordSuccess: (recordType, deprecatedRecord) => dispatch => {
        dispatch({ type: actionTypes.deprecateRecordSuccess, recordType, deprecatedRecord });
    }
};
