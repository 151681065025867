import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as tmdbStore from './';
import axios from 'axios';

export const sagaWatchers = (sagaMiddleware) => {
    sagaMiddleware.run(watchGetMovies);
    sagaMiddleware.run(watchSearchMovies);
    sagaMiddleware.run(watchGetTmdbConfiguration);
};

function* watchGetMovies() {
    yield takeEvery(actionTypes.getMoviesSaga, getMovies);
}

function* getMovies() {
    yield put(tmdbStore.actionCreators.receiveMovies());
}

function* watchSearchMovies() {
    yield takeEvery(actionTypes.searchMoviesSaga, searchMovies);
}

function* searchMovies({ searchText }) {
    if (searchText && searchText.length > 0) {
        const urlSearch = `/api/tmdb/search?searchText=${searchText}`;
        const result = yield axios.get(urlSearch).catch(error => error);
        yield put(tmdbStore.actionCreators.receiveSearchMovieResults(result.data.results));
    } else {
        yield put(tmdbStore.actionCreators.receiveSearchMovieResults([]));
    }
}

function* watchGetTmdbConfiguration() {
    yield takeEvery(actionTypes.getTmdbConfigurationSaga, getTmdbConfiguration);
}

function* getTmdbConfiguration(action) {
    const urlSearch = `/api/tmdb/configuration`;
    const result = yield axios.get(urlSearch).catch(error => error);
    yield put(tmdbStore.actionCreators.receiveTmdbConfiguration(result.data));
}