import React, { useState, useCallback, useEffect } from 'react';
import * as tmdbStore from '../../r/tmdbStore';
import * as rnryStore from '../../r/rnryStore';
import * as recordStore from '../../r/recordStore';
import * as authStore from '../../r/authStore';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MovieSearchDialog } from './MovieSearchDialog';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import * as constants from '../../r/constants';
import { SearchBoxHeader } from '../helpers/SearchBoxHeader';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarBorder';

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const MovieLibraryPage = () => {

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const tmdbConfiguration = useSelector(state => tmdbStore.selectors.selectTmdbConfiguration(state));
    const userLibraryMovies = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryUserLibraryMetadataMaster));
    const libraryRecordsExpandedAll = useSelector(state => rnryStore.selectors.selectLibraryExpandedMovies(state, constants.rnryLibraryTypes.user));
    const addMetadataToLibrary = useCallback(record => dispatch(recordStore.actionCreators.createRecord(constants.rnryRecordTypes.rnryUserLibraryMetadataMaster, record)), [dispatch]);
    const removeLibraryMovie = useCallback(record => dispatch(recordStore.actionCreators.deleteRecord(constants.rnryRecordTypes.rnryUserLibraryMetadataMaster, record)), [dispatch]);
    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const tnsUserId = useSelector(state => authStore.selectors.selectTnsUserId(state));

    const classes = {
        posterBoxList: {
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            backgroundColor: 'grey.800',
            overflowY: 'auto',
            position: 'relative'
        },
        posterBoxBorder: {
            border: 2,
            borderColor: 'grey.800',
            textDecoration: 'none',
            borderRadius: '10px',
            margin: '12px',
            //position: 'relative',
            backgroundColor: 'grey.900',
            boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
            mozUserSelect: 'none',
            msUserSelect: 'none',
            webkitUserSelect: 'none',
            userSelect: 'none',
            transform: 'translate(0%, 0%)',
            transition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            webkitTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            mozTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            oTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            '&:hover': {
                cursor: 'pointer',
                transform: 'translate(-2%, -2%)',
                transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            },
            '&:active': {
                cursor: 'pointer',
                transform: 'translate(-1%, -1%)',
                transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            }
        },
        posterBox: {
            display: 'flex',
            flexDirection: 'column',
            width: '200px',
            height: '356px',
            margin: 2,
            textDecoration: 'none',

        },
        posterBoxTitle: {
            marginTop: 2,
            fontSize: 'body2.fontSize',
            color: 'common.white',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden'
        },
        posterBoxYear: {
            marginTop: 0,
            fontSize: 'body2.fontSize',
            color: 'grey.500',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden'
        },
        progressContainer: {
            display: 'flex',
            flexGrow: 1,
            mt: 10,
            justifyContent: 'center'
        },
        pageContent: {
            backgroundColor: 'grey.800',
            flexGrow: 1,
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        menuBarSpacer: {
            flexGrow: 1
        },
        addButton: {
            ml: 2,
            height: '24px',
            width: '24px',
            minHeight: '24px'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            m: 0
        },
        menuBar: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 0,
            m: 3
        },
        searchBoxHeader: {
            width: '100%',
            maxWidth: '1000px'
        },
        bookmarkIcon: {
            height: '32px',
            width: '32px'
        },
        bookmarkIconBox: {
            width: '40px',
            height: '40px',
            backgroundColor: '#831c1c',
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cornerRadius: '0 10px 0 10px'
        },
        placeholderText: {
            fontSize: 24,
            fontStyle: 'italic'
        }
    }

    const [searchText, setSearchText] = useState('');
    const minSearchTextLength = 1;
    const [libraryRecordsExpanded, setLibraryRecordsExpanded] = useState([]);

    const applyFilter = useCallback(() => {
        if (libraryRecordsExpandedAll.length > 0 && searchText.trim().length >= minSearchTextLength) {
            // TODO: Include metadata in objects and search on linked movie title in addition to post title
            setLibraryRecordsExpanded(libraryRecordsExpandedAll.filter(x => x.metadata.displayTitle.toLowerCase().includes(searchText.trim().toLowerCase())));
        } else {
            setLibraryRecordsExpanded([...libraryRecordsExpandedAll]);
        }
    }, [libraryRecordsExpandedAll, searchText]);

    useEffect(() => {
        applyFilter();
    }, [searchText, applyFilter]);

    useEffect(() => {
        if (libraryRecordsExpandedAll) {
            setLibraryRecordsExpanded([...libraryRecordsExpandedAll]);
            applyFilter();
        }
    }, [applyFilter, libraryRecordsExpandedAll]);

    const isLoaded = useCallback(() => {
        return libraryRecordsExpandedAll && libraryRecordsExpandedAll.length >= 0 && tmdbConfiguration && tmdbConfiguration.images;
    }, [libraryRecordsExpandedAll, tmdbConfiguration]);

    const removeFromLibrary = useCallback(metadata => {
        if (isLoggedIn) {
            const libraryMovie = metadata && userLibraryMovies.find(lib => lib.rnryMetadataMasterId === metadata.id);
            if (libraryMovie) {
                removeLibraryMovie(libraryMovie);
            }
        }
    }, [isLoggedIn, userLibraryMovies, removeLibraryMovie]);

    const addToLibrary = useCallback(metadata => {
        if (isLoggedIn) {
            const userLib = {
                rnryMetadataMasterType: metadata.rnryMetadataType,
                rnryMetadataMasterId: metadata.id,
                tnsUserId: tnsUserId
            }
            addMetadataToLibrary(userLib);
        }
    }, [addMetadataToLibrary, isLoggedIn, tnsUserId]);

    const getLibraryIcon = metadataMovie => {
        const isIn = metadataMovie && userLibraryMovies.some(lib => lib.rnryMetadataMasterId === metadataMovie.id);
        const tooltip = isIn ? 'Remove from library' : 'Add to library';
        const starIcon = isIn ? <StarIcon sx={classes.bookmarkIcon} /> : <StarOutlineIcon sx={{ ...classes.bookmarkIcon, color: 'rgb(255,255,255,0.5)' }} />;
        const icon = (
            <DelayedTooltip title={tooltip}>
                <Box sx={classes.bookmarkIconBox}>
                    <IconButton disableRipple onClick={isIn ? () => removeFromLibrary(metadataMovie) : () => addToLibrary(metadataMovie)}>
                        {starIcon}
                    </IconButton>
                </Box>
            </DelayedTooltip>
        );
        return icon;
    };

    const getList = () => {
        return libraryRecordsExpanded.sort((a, b) => a.metadata.displayTitle > b.metadata.displayTitle ? 1 : -1).map(lib => {
            return (
                <Box key={lib.id} sx={classes.posterBoxBorder}>
                    <Box component={Link} sx={classes.posterBox} key={lib.id} to={`/movies/${lib.rnryMetadataMasterId}`}>
                        <img width={200} height={300} src={`${tmdbConfiguration.images.secure_base_url}${tmdbConfiguration.images.poster_sizes[4]}${lib.metadata.tmdbPosterPath}`} alt={lib.metadata.displayTitle} loading={'lazy'} />
                        <Typography sx={classes.posterBoxTitle}>{lib.metadata.displayTitle}</Typography>
                        <Typography sx={classes.posterBoxYear}>{lib.metadata.releaseDate?.substring(0, 4)}</Typography>
                    </Box>
                    {getLibraryIcon(lib.metadata)}
                </Box>
            );
        });
    }

    return (
        <Box sx={classes.container}>
            <MovieSearchDialog isOpen={isOpen} setIsOpen={setIsOpen} />
            <Box sx={classes.menuBar}>
                <Typography>{'Movie Library'}</Typography>
                <Tooltip title={'Add Movies To Your Library'} arrow placement={'right'}>
                    <Fab sx={classes.addButton} size={'small'} onClick={() => setIsOpen(true)} color={'primary'}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Box>
            <Box sx={classes.pageContent}>
                {!isLoaded() &&
                    <Box sx={classes.progressContainer}>
                        <CircularProgress />
                    </Box>
                }
                {isLoaded() && libraryRecordsExpandedAll.length === 0 &&
                    <Box sx={classes.progressContainer}>
                        <Typography sx={classes.placeholderText}>{'Add movies to your library using the plus icon above'}</Typography>
                    </Box>
                }
                {isLoaded() && libraryRecordsExpandedAll.length > 0 &&
                    <React.Fragment>
                        <SearchBoxHeader sx={classes.searchBoxHeader} searchText={searchText} setSearchText={setSearchText} minLength={minSearchTextLength} />
                        <Box sx={classes.posterBoxList}>
                            {getList()}
                        </Box>
                    </React.Fragment>
                }
            </Box>
        </Box>
    );
};