import * as actionTypes from './actionTypes';

const initialState = {
    isConfirmProfileOpen: false,
    reactorRnryUserIdToManage: ''
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case actionTypes.setIsConfirmProfileOpen:
            return {
                ...state,
                isConfirmProfileOpen: action.isOpen
            };

        case actionTypes.setReactorRnryUserIdToManage:
            return {
                ...state,
                reactorRnryUserIdToManage: action.rnryUserId
            };

        default:
            return state;
    }
};
