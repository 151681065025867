import React, { useCallback, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import * as authStore from '../../r/authStore';
import * as tmdbStore from '../../r/tmdbStore';
import * as recordStore from '../../r/recordStore';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarBorder';
import Slide from '@mui/material/Slide';
import * as constants from '../../r/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TmdbSearchDialog = ({ isOpen, setIsOpen, libraryRecords, libraryRecordsExpanded, addTmdbRecordToLibrary, removeLibraryRecord }) => {

    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');
    const [searchMovieResultsState, setSearchMovieResultsState] = useState([]);

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));

    const searchMovieResults = useSelector(state => tmdbStore.selectors.selectSearchMovieResults(state));
    const searchMovies = useCallback(() => dispatch(tmdbStore.actionCreators.searchMovies(searchText)), [dispatch, searchText]);
    const resetSearch = useCallback(() => dispatch(tmdbStore.actionCreators.searchMovies('')), [dispatch]);

    const tmdbConfiguration = useSelector(state => tmdbStore.selectors.selectTmdbConfiguration(state));
    const metadataMovies = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryMetadataMovie));

    useEffect(() => {
        if (isOpen && searchText && searchText.length > 0) {
            setSearchMovieResultsState(searchMovieResults);
        }
    }, [searchMovieResults, isOpen, searchText]);

    const removeFromLibrary = useCallback(tmdb => {
        if (isLoggedIn) {
            const metadataMovie = metadataMovies.find(x => x.tmdbId === tmdb.id.toString());
            const libraryMovie = metadataMovie && libraryRecords.find(lib => lib.rnryContentId === metadataMovie.id);
            if (libraryMovie) {
                removeLibraryRecord(libraryMovie);
            }
        }
    }, [metadataMovies, isLoggedIn, libraryRecords, removeLibraryRecord]);

    const addToLibrary = useCallback(tmdb => {
        if (isLoggedIn) {
            addTmdbRecordToLibrary(tmdb);
        }
    }, [addTmdbRecordToLibrary, isLoggedIn]);

    const getLibraryIcon = tmdb => {
        const metadataMovie = metadataMovies.find(x => x.tmdbId === tmdb.id.toString().toLowerCase().trim());
        const isIn = metadataMovie && libraryRecords.some(lib => lib.rnryMetadataMasterId === metadataMovie.id);
        let isDisabled = false;
        if (metadataMovie) {
            const libraryRecord = libraryRecordsExpanded.find(lib => lib.rnryMetadataMasterId === metadataMovie.id);
            if (libraryRecord && libraryRecord.rnryReactionMedias && libraryRecord.rnryReactionMedias.length > 0) {
                isDisabled = true;
            }
        }
        const starIcon = isIn ? <StarIcon sx={classes.bookmarkIcon} /> : <StarOutlineIcon sx={{ ...classes.bookmarkIcon, color: 'rgb(255,255,255,0.5)' }} />;
        const icon = (
            <Box sx={classes.bookmarkIconBox}>
                <IconButton disableRipple disabled={isDisabled} onClick={isIn ? () => removeFromLibrary(tmdb) : () => addToLibrary(tmdb)}>
                    {starIcon}
                </IconButton>
            </Box>
        );
        return icon;
    };

    const handleClose = () => {
        setSearchText('');
        setSearchMovieResultsState([]);
        resetSearch();
        setIsOpen(false);
    };

    const handleKeyDown = e => {
        if (searchText.trim().length >= 2 && e.key === 'Enter') {
            searchMovies();
        }
    }

    const getImageUrl = tmdb => {
        if (tmdb.poster_path) {
            return `${tmdbConfiguration.images.secure_base_url}${tmdbConfiguration.images.poster_sizes[4]}${tmdb.poster_path}`;
        } else {
            return '/i/no-image.jpg';
        }
    }

    const classes = {
        posterBoxList: {
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            backgroundColor: 'grey.800'
        },
        posterBoxBorder: {
            border: 2,
            borderColor: 'grey.800',
            borderRadius: '10px',
            margin: '12px',
            backgroundColor: 'grey.900',
            boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
            mozUserSelect: 'none',
            msUserSelect: 'none',
            webkitUserSelect: 'none',
            userSelect: 'none',
            transform: 'translate(0%, 0%)',
            transition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            webkitTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            mozTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            oTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
            '&:hover': {
                //cursor: 'pointer',
                transform: 'translate(-2%, -2%)',
                transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            },
            '&:active': {
                //cursor: 'pointer',
                transform: 'translate(-1%, -1%)',
                transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
                oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            }
        },
        posterBox: {
            display: 'flex',
            flexDirection: 'column',
            width: '200px',
            height: '356px',
            margin: 2
        },
        posterBoxTitle: {
            marginTop: 2,
            fontSize: 'body2.fontSize',
            color: 'common.white',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden'
        },
        posterBoxYear: {
            marginTop: 0,
            fontSize: 'body2.fontSize',
            color: 'grey.500',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden'
        },
        bookmarkIcon: {
            height: '32px',
            width: '32px'
        },
        bookmarkIconBox: {
            width: '40px',
            height: '40px',
            backgroundColor: '#831c1c',
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cornerRadius: '0 10px 0 10px'
        }
    }

    return (
        <Dialog
            fullScreen
            disableRestoreFocus
            open={isOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Add Movies
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 3 }} >
                <Stack direction={'row'} m='10px'>
                    <TextField autoFocus autoComplete={'off'} onKeyDown={handleKeyDown} sx={{ flexGrow: 1, mr: 1 }} label={'Search'} variant={'outlined'} value={searchText} onChange={e => setSearchText(e.target.value)} />
                    <Button disabled={searchText.trim().length < 2} onClick={searchMovies}>Search</Button>
                </Stack>
                <Box sx={classes.posterBoxList}>
                    {searchMovieResultsState.map(tmdb => (
                        <Box sx={classes.posterBoxBorder} key={tmdb.id}>
                            <Box sx={classes.posterBox}>
                                <img width={200} height={300} src={getImageUrl(tmdb)} alt={tmdb.title} loading={'lazy'} />
                                <Tooltip title={tmdb.title}>
                                    <Typography sx={classes.posterBoxTitle}>{tmdb.title}</Typography>
                                </Tooltip>
                                <Typography sx={classes.posterBoxYear}>{tmdb.release_date?.substring(0, 4)}</Typography>
                            </Box>
                            {getLibraryIcon(tmdb)}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Dialog>
    );
};