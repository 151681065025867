import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import FreeIcon from '@mui/icons-material/MoneyOff';
import * as constants from '../../r/constants';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import FirstTimeWatchedIcon from '@mui/icons-material/RepeatOne';
import RepeatWatchIcon from '@mui/icons-material/Repeat';
import FullLengthIcon from '@mui/icons-material/HourglassFull';
import EditedIcon from '@mui/icons-material/ContentCut';

const classes = {
    mediaIcons: {
        mt: 0,
        display: 'flex'
    }
};

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const ReactionMediaDetailsIconStrip = ({ reactionMedia, iconSize }) => {

    const getIsFreeIcon = () => {
        let tooltip = 'Reaction requires paid access';
        let icon = <MoneyIcon fontSize={iconSize} sx={{}} />;
        if (reactionMedia.isFree) {
            tooltip = 'Reaction is free to access';
            icon = <FreeIcon fontSize={iconSize} sx={{ color: 'gray' }} />;
        }
        return (
            <Box sx={{ mr: 0 }} >
                <DelayedTooltip title={tooltip}>
                    {icon}
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsBlurredIcon = () => {
        let tooltip = 'Media content is not blurred out';
        let icon = <BlurOffIcon fontSize={iconSize} sx={{ color: 'gray' }} />;
        if (reactionMedia.isBlurred) {
            tooltip = 'Media content is blurred out';
            icon = <BlurOnIcon fontSize={iconSize} sx={{}} />;
        }
        return (
            <Box sx={{ mr: 1 }} >
                <DelayedTooltip title={tooltip}>
                    {icon}
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsFirstTimeWatchedIcon = () => {
        let tooltip = 'Reaction is a repeat watch';
        let icon = <RepeatWatchIcon fontSize={iconSize} sx={{ color: 'gray' }} />;
        if (reactionMedia.isFirstTimeWatch) {
            tooltip = 'Reaction is a first time watch';
            icon = <FirstTimeWatchedIcon fontSize={iconSize} sx={{}} />;
        }
        return (
            <Box sx={{ mr: 1 }} >
                <DelayedTooltip title={tooltip}>
                    {icon}
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsFullLengthIcon = () => {
        let tooltip = 'Reaction is edited';
        let icon = <EditedIcon fontSize={iconSize} sx={{}} />;
        if (reactionMedia.rnryReactionType === constants.rnryReactionTypes.fullLength) {
            tooltip = 'Reaction is full length';
            icon = <FullLengthIcon fontSize={iconSize} sx={{}} />;
        }
        if (reactionMedia.rnryReactionType === constants.rnryReactionTypes.fullLength || reactionMedia.rnryReactionType === constants.rnryReactionTypes.edited) {
            return (
                <Box sx={{ mr: 1 }} >
                    <DelayedTooltip title={tooltip}>
                        {icon}
                    </DelayedTooltip>
                </Box>
            );
        }
    };

    return (reactionMedia &&
        <Box sx={classes.mediaIcons}>
            {getIsFullLengthIcon()}
            {getIsFirstTimeWatchedIcon()}
            {getIsBlurredIcon()}
            {getIsFreeIcon()}
        </Box>
    );
};