import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MovieIcon from '@mui/icons-material/Theaters';
import TelevisionIcon from '@mui/icons-material/Tv';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import { useLocation, Outlet, Link as RouterLink } from 'react-router-dom';
import ReactorIcon from '@mui/icons-material/PhotoCameraFront';
import ManageReactionsIcon from '@mui/icons-material/AddReaction';
import PostsIcon from '@mui/icons-material/Signpost';
import ProfileIcon from '@mui/icons-material/ManageAccounts';
import { useSelector } from 'react-redux';
import * as rnryStore from '../../r/rnryStore';
import { AuthUserMenu } from '../auth/AuthUserMenu';

const menuItems = [
    {
        menuIndex: 0,
        menuText: 'Home',
        menuIcon: <HomeIcon />,
        menuLink: '/'
    },
    {
        menuIndex: 1,
        menuText: 'Movies',
        menuIcon: <MovieIcon />,
        menuLink: '/movies'
    },
    {
        menuIndex: 2,
        menuText: 'TV Shows',
        menuIcon: <TelevisionIcon />,
        menuLink: '/shows'
    },
    {
        menuIndex: 3,
        menuText: 'Reactors',
        menuIcon: <ReactorIcon />,
        menuLink: '/reactors'
    },
    {
        menuIndex: 4,
        menuText: 'Manage Reactions',
        menuIcon: <ManageReactionsIcon />,
        menuLink: '/reactions',
        dividerBefore: true,
        requireCreator: true
    },
    {
        menuIndex: 5,
        menuText: 'Patreon Posts',
        menuIcon: <PostsIcon />,
        menuLink: '/patreonposts',
        requireCreator: true
    },
    {
        menuIndex: 6,
        menuText: 'User Profile',
        menuIcon: <ProfileIcon />,
        menuLink: '/user',
        dividerBefore: true
    },
    {
        menuIndex: 7,
        menuText: 'Help',
        menuIcon: <HelpIcon />,
        menuLink: '/help',
        dividerBefore: false
    }
];

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const NavDrawerShell = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    const patreonUserData = useSelector(state => rnryStore.selectors.selectPatreonUserData(state));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getMenuItem = mi => {
        if (mi.requireCreator && (!patreonUserData || !patreonUserData.isCreator)) {
            return [];
        } else {
            return (
                <React.Fragment key={mi.menuText} >
                    {
                        mi.dividerBefore && <Divider />
                    }
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            component={RouterLink}
                            to={mi.menuLink}
                            selected={(mi.menuLink === location.pathname && location.pathname === '/') || (mi.menuLink !== '/' && location.pathname.startsWith(mi.menuLink))}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {mi.menuIcon}
                            </ListItemIcon>
                            <ListItemText primary={mi.menuText} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>
            )
        }
    }

    return (
        <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', borderRadius: '3px', p: '1px', boxShadow: '2px 2px 2px 1px #121212', border: '1px #373737 solid' }} >
                        <img width={'190px'} src={'/i/logo-reactionairy.png'} alt={'Reactionairy logo'} />
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <AuthUserMenu />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {menuItems.map(mi => getMenuItem(mi))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 0, mt: '64px', height: 'calc(100% - 64px)', display: 'flex' }}>
                <Outlet />
            </Box>
        </Box >
    );
}