import React, { useCallback, useState, useEffect } from 'react';
import * as constants from '../../r/constants';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import * as recordStore from '../../r/recordStore';
import * as rnryStore from '../../r/rnryStore';
import { ReactionMediaDetailsEditorIconStrip } from './ReactionMediaDetailsEditorIconStrip';
import FullLengthIcon from '@mui/icons-material/HourglassFull';
import EditedIcon from '@mui/icons-material/ContentCut';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs';

const classes = {
    outerContainer: {
        //position: 'relative',
        width: 260,
        //boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        //mt: 0
    },
    popoverPaper: {
        boxShadow: '6px 6px 10px -1px rgba(0,0,0,0.4), 10px 10px 16px 0px rgba(0,0,0,0.28), 2px 2px 28px 0px rgba(0,0,0,0.24)',
    },
    reactionDetails: {
        display: 'flex',
        alignItems: 'center',
        border: '3px #00FFFF solid',
        borderRadius: '5px',
        p: 2,
        flexDirection: 'column',
        backgroundColor: '#000000',

    },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        my: 1,
        ml: 1
    },
    leftColumnTopRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    leftColumnBottomRow: {
        display: 'flex',
        flexDirection: 'row',
        mt: 1,
        height: '16px',
        alignItems: 'center'
    },
    rightColumn: {
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        mr: '3px',
        my: '3px',
    },
    headerRow: {
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'italic'
    }
}

const getLogo = platformCode => {
    switch (platformCode) {
        case constants.reactionVideoHostCodes.youtube:
            return <img height={16} src={'/i/youtube/yt_logo_rgb_dark.png'} alt={'YouTube'} loading={'lazy'} />;

        case constants.reactionVideoHostCodes.patreon:
            return <img width={72} src={'/i/patreon/patreon_wordmark_white.png'} alt={'Patreon'} loading={'lazy'} />;

        case constants.reactionVideoHostCodes.vimeo:
            return <img height={16} src={'/i/vimeo/vimeo_logo_blue.png'} alt={'Vimeo'} loading={'lazy'} />;

        default:
    }
};

const patreonPostUrlPrefix = 'https://www.patreon.com/posts/';

export const ReactionMediaMiniDetailsEditor = ({ isOpen, setIsOpen, libraryExpandedRecord, reactionMedia, anchorRef }) => {

    const dispatch = useDispatch();

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const updateReactionRecord = useCallback(record => dispatch(recordStore.actionCreators.updateRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, record, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);

    const [hostProviderCode, setHostProviderCode] = useState('');
    const [patreonPostUrl, setPatreonPostUrl] = useState('');
    const [isFree, setIsFree] = useState(false);
    const [isBlurred, setIsBlurred] = useState(false);
    const [isFirstTimeWatch, setIsFirstTimeWatch] = useState(false);
    const [reactionDate, setReactionDate] = useState(dayjs());
    const [dateError, setDateError] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsFree(reactionMedia.isFree);
            setIsBlurred(reactionMedia.isBlurred);
            setIsFirstTimeWatch(reactionMedia.isFirstTimeWatch);
            setHostProviderCode(reactionMedia.reactionVideoHost);
            setPatreonPostUrl(reactionMedia.patreonPostUrl.replace(patreonPostUrlPrefix, ''));
            setReactionDate(dayjs(reactionMedia.reactionDate));
        } else {
            //setIsFree(false);
            //setIsBlurred(false);
            //setIsFirstTimeWatch(false);
            //setHostProviderCode('');
            //setPatreonPostUrl('');
            //setReactionDate('0001-01-01');
        }
    }, [reactionMedia, isOpen]);

    const isDirty = useCallback(() => {
        return (
            isFree !== reactionMedia.isFree ||
            isBlurred !== reactionMedia.isBlurred ||
            isFirstTimeWatch !== reactionMedia.isFirstTimeWatch ||
            hostProviderCode !== reactionMedia.reactionVideoHost ||
            patreonPostUrl !== reactionMedia.patreonPostUrl.replace(patreonPostUrlPrefix, '') ||
            dayjs(reactionDate).format('YYYY-MM-DD') !== reactionMedia.reactionDate
        );
    }, [reactionMedia, reactionDate, isFree, isBlurred, isFirstTimeWatch, hostProviderCode, patreonPostUrl]);

    const handleSave = useCallback(() => {
        if (isDirty() && !dateError) {
            let cleanUrl = '';
            if (patreonPostUrl && patreonPostUrl.replace(patreonPostUrlPrefix, '').trim() !== '') {
                cleanUrl = `${patreonPostUrlPrefix}${patreonPostUrl.replace(patreonPostUrlPrefix, '').trim()}`
            }
            const updatedRecord = { ...reactionMedia };
            updatedRecord.isFree = isFree;
            updatedRecord.isBlurred = isBlurred;
            updatedRecord.isFirstTimeWatch = isFirstTimeWatch;
            updatedRecord.reactionVideoHost = hostProviderCode;
            updatedRecord.patreonPostUrl = cleanUrl;
            updatedRecord.reactionDate = dayjs(reactionDate).format('YYYY-MM-DD');
            updateReactionRecord(updatedRecord);
            setIsOpen(false);
        }
    }, [dateError, isDirty, reactionMedia, reactionDate, isFree, isBlurred, isFirstTimeWatch, hostProviderCode, patreonPostUrl, updateReactionRecord, setIsOpen]);

    const handleClose = useCallback((e, reason) => {
        if (reason === 'escapeKeyDown') {
            setIsOpen(false);
        }
    }, [setIsOpen]);

    const getHeader = useCallback(() => {
        let icon = [];
        let text = '';
        switch (reactionMedia.rnryReactionType) {
            case constants.rnryReactionTypes.fullLength:
                icon = <FullLengthIcon fontSize={'small'} />;
                text = 'FULL LENGTH REACTION'
                break;

            case constants.rnryReactionTypes.edited:
                icon = <EditedIcon fontSize={'small'} />;
                text = 'EDITED REACTION'
                break;

            default:
                return [];
        }
        return (
            <Box sx={classes.headerRow}>
                {icon}
                <Typography sx={{ ml: 1, fontSize: '0.9rem' }} >{text}</Typography>
            </Box>
        );
    }, [reactionMedia]);

    const handleDateError = error => {
        if (error) {
            setDateError(true);
        } else {
            setDateError(false);
        }
    };

    return (
        <React.Fragment>
            <Backdrop open={isOpen} sx={{ backgroundColor: '#22222288' }} />
            <Popover anchorOrigin={{ vertical: 'top', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} anchorEl={anchorRef} open={isOpen} onClose={handleClose} slotProps={{ paper: { sx: classes.popoverPaper } }} >

                <Box sx={classes.outerContainer}>
                    <Box sx={{ ...classes.reactionDetails }}>
                        {getHeader()}
                        <ReactionMediaDetailsEditorIconStrip
                            isFree={isFree}
                            setIsFree={setIsFree}
                            isBlurred={isBlurred}
                            setIsBlurred={setIsBlurred}
                            isFirstTimeWatch={isFirstTimeWatch}
                            setIsFirstTimeWatch={setIsFirstTimeWatch}
                        />
                        <FormControl variant={'standard'} sx={{ mt: 2 }} fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={hostProviderCode}
                                label="Video Host"
                                onChange={e => setHostProviderCode(e.target.value)}
                            >
                                <MenuItem value={'patreon'}>
                                    <img width={72} src={'/i/patreon/patreon_wordmark_white.png'} alt={'Patreon'} loading={'lazy'} />
                                </MenuItem>
                                <MenuItem value={'vimeo'}>
                                    <img height={16} src={'/i/vimeo/vimeo_logo_blue.png'} alt={'Vimeo'} loading={'lazy'} />
                                </MenuItem>
                                <MenuItem value={'youtube'}>
                                    <img height={16} src={'/i/youtube/yt_logo_rgb_dark.png'} alt={'YouTube'} loading={'lazy'} />
                                </MenuItem>
                            </Select>
                            <FormHelperText>{'Video Host'}</FormHelperText>
                        </FormControl>
                        <TextField value={patreonPostUrl} InputLabelProps={{ shrink: true }} onChange={e => setPatreonPostUrl(e.target.value)} helperText={'Patreon Post URL'} label={'https://www.patreon.com/posts/'} fullWidth variant={'standard'} sx={{ mt: 2 }} />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onError={handleDateError} value={reactionDate} onChange={newValue => setReactionDate(newValue)} sx={{ mt: 2 }} slotProps={{ textField: { helperText: 'Reaction Date', InputLabelProps: { shrink: true }, variant: 'standard' } }} />
                        </LocalizationProvider>
                        <Box sx={{ mt: 2, alignSelf: 'flex-end' }}>
                            <Button sx={{ mr: 2, width: '80px' }} variant={'outlined'} onClick={() => setIsOpen(false)} color={'error'}>{'Cancel'}</Button>
                            <Button sx={{ width: '80px' }} variant={'outlined'} color={'success'} onClick={handleSave} disabled={!isDirty() || dateError}>{'Save'}</Button>
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </React.Fragment>
    );
};