import React, { useCallback, useState, useRef } from 'react';
import * as constants from '../../r/constants';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ExternalLinkIcon from '@mui/icons-material/Launch';
import Fade from '@mui/material/Fade';
import { ReactionMediaDetailsIconStrip } from './ReactionMediaDetailsIconStrip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { AddReactionMediaButton } from './AddReactionMediaButton';
import * as recordStore from '../../r/recordStore';
import * as rnryStore from '../../r/rnryStore';
import { Link as RouterLink } from 'react-router-dom';
import { ReactionMediaMiniDetailsEditor } from './ReactionMediaMiniDetailsEditor';
import { useSelector, useDispatch } from 'react-redux';

const classes = {
    outerContainer: {
        position: 'relative',
        mt: 1
    },
    reactionDetails: {
        display: 'flex',
        alignItems: 'center',
        border: '1px black solid',
        borderRadius: '5px',
        backgroundColor: '#121212'
    },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        my: 1,
        ml: 1
    },
    leftColumnTopRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    leftColumnBottomRow: {
        display: 'flex',
        flexDirection: 'row',
        mt: 1,
        height: '16px',
        alignItems: 'center'
    },
    rightColumn: {
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        mr: '3px',
        my: '3px',

    },
    pseudoButton: {
        display: 'flex',
        color: 'grey.400',
        '&:hover': {
            cursor: 'pointer',
            color: 'white'
        }
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        border: '1px #707070 solid',
        borderRadius: '5px',
        backgroundColor: '#000000DD',
        justifyContent: 'flex-start'
    },
    overlayButton: {
        minWidth: 24,
        ml: '12px',
        backgroundColor: 'black',
        '&:hover': {
            backgroundColor: '#121212'
        }
    },
    addNewButtonContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}

const getLogo = platformCode => {
    switch (platformCode) {
        case constants.reactionVideoHostCodes.youtube:
            return <img height={16} src={'/i/youtube/yt_logo_rgb_dark.png'} alt={'YouTube'} loading={'lazy'} />;

        case constants.reactionVideoHostCodes.patreon:
            return <img width={72} src={'/i/patreon/patreon_wordmark_white.png'} alt={'Patreon'} loading={'lazy'} />;

        case constants.reactionVideoHostCodes.vimeo:
            return <img height={16} src={'/i/vimeo/vimeo_logo_blue.png'} alt={'Vimeo'} loading={'lazy'} />;

        default:
    }
};

export const ReactionMediaMiniDetails = ({ libraryExpandedRecord, reactionMedia, sx, rnryReactionType }) => {

    const dispatch = useDispatch();

    const anchorRef = useRef(null);

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const deleteReactionMediaFromLibrary = useCallback(recordId => dispatch(recordStore.actionCreators.deleteRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, recordId, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);

    const deleteRecord = () => {
        var t = true;
        deleteReactionMediaFromLibrary(reactionMedia);
    }

    const handleOpenEditor = () => {
        setIsContextMenuOpen(false);
        setIsEditorOpen(true);
    }

    const handleOpenEditorFromAddNew = () => {
        setIsEditorOpen(true);
    }

    return (reactionMedia ?
        (
            <Box sx={{ ...classes.outerContainer, ...sx }} ref={anchorRef} onMouseLeave={() => setIsContextMenuOpen(false)}>
                <ReactionMediaMiniDetailsEditor anchorRef={anchorRef.current} isOpen={isEditorOpen} setIsOpen={setIsEditorOpen} reactionMedia={reactionMedia} />
                <Fade in={isContextMenuOpen} timeout={{ enter: 300, exit: 500 }}>
                    <Box sx={classes.overlay}>
                        <Button onClick={handleOpenEditor} sx={{ ...classes.overlayButton, minWidth: 24 }} color={'primary'} variant={'outlined'}>
                            <EditIcon />
                        </Button>
                        <Button onClick={deleteRecord} sx={{ ...classes.overlayButton, minWidth: 24 }} color={'error'} variant={'outlined'}>
                            <DeleteIcon />
                        </Button>
                    </Box>
                </Fade>
                <Box sx={{ ...classes.reactionDetails }}>
                    <Box sx={classes.leftColumn}>
                        <Box sx={classes.leftColumnTopRow}>
                            <ReactionMediaDetailsIconStrip reactionMedia={reactionMedia} />
                        </Box>
                        <Box sx={classes.leftColumnBottomRow}>
                            {getLogo(reactionMedia.reactionVideoHost)}
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={classes.rightColumn}>
                        {reactionMedia.patreonPostUrl &&
                            <Box disabled={reactionMedia.patreonPostUrl === ''} sx={classes.pseudoButton} component={RouterLink} to={reactionMedia.patreonPostUrl} target={'_blank'} rel={'noreferrer'}>
                                <ExternalLinkIcon fontSize={'small'} />
                            </Box>
                        }
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={classes.pseudoButton}>
                            <MoreVertIcon onClick={() => setIsContextMenuOpen(true)} fontSize={'small'} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box ref={anchorRef} sx={classes.addNewButtonContainer}>
                <AddReactionMediaButton openEditor={handleOpenEditor} rnryReactionType={rnryReactionType} libraryExpandedRecord={libraryExpandedRecord} />
            </Box>
        )
    );

};