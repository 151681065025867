import React, { useCallback, useEffect, useState } from 'react';
import * as patreonStore from '../../r/patreonStore';
import * as rnryStore from '../../r/rnryStore';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PatreonPost } from './PatreonPost';
import { ReactionLibraryDialog } from './ReactionLibraryDialog';
import { SearchBoxHeader } from '../helpers/SearchBoxHeader';

const classes = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 0,
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none'
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        m: 5,
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none'
    },
    pageContent: {
        backgroundColor: 'grey.800',
        flexGrow: 1,
        height: '100%',
        overflowY: 'auto'
    }
}

export const PatreonPostsPage = () => {

    const dispatch = useDispatch();

    const minSearchTextLength = 1;

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const rnryUserRecordToManage = useSelector(state => rnryStore.selectors.selectReactorRecordForUserToManage(state));
    const [showLinkedPosts, setShowLinkedPosts] = useState(true);
    const [isLibraryDialogOpen, setIsLibraryDialogOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
    //const [applyFilter, setApplyFilter] = useState(false);
    const [currentRequestedPatreonPostToLink, setCurrentRequestedPatreonPostToLink] = useState({});

    const getPatreonPosts = useCallback(() => dispatch(patreonStore.actionCreators.getPatreonPostsForRnryUserId(rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);
    const patreonPostsAll = useSelector(state => patreonStore.selectors.selectPatreonPostsWithLinkedReactions(state));
    const isLoading = useSelector(state => patreonStore.selectors.selectIsLoadingPatreonPosts(state));
    const [patreonPosts, setPatreonPosts] = useState([]);

    useEffect(() => {
        if (rnryUserRecordToManage && rnryUserRecordToManage.rnryUserRecord && rnryUserRecordToManage.rnryUserRecord.hasTnsAuthAccount) {
            getPatreonPosts();
        }
    }, [getPatreonPosts, rnryUserRecordToManage]);

    const applyFilter = useCallback(() => {
        if (patreonPosts.length > 0 && searchText.trim().length >= minSearchTextLength) {
            // TODO: Include metadata in objects and search on linked movie title in addition to post title
            setFilteredPosts(patreonPosts.filter(x => x.attributes.title.toLowerCase().includes(searchText.trim().toLowerCase())));
        } else {
            setFilteredPosts([...patreonPosts]);
        }
    }, [patreonPosts, searchText]);

    useEffect(() => {
        applyFilter();
    }, [searchText, applyFilter]);

    useEffect(() => {
        if (patreonPosts) {
            setFilteredPosts([...patreonPosts]);
            applyFilter();
        }
    }, [applyFilter, patreonPosts]);

    useEffect(() => {
        if (patreonPostsAll) {
            if (showLinkedPosts) {
                setPatreonPosts([...patreonPostsAll]);
            } else {
                setPatreonPosts(patreonPostsAll.filter(x => !x.libraryRecord));
            }
        }
    }, [patreonPostsAll, showLinkedPosts]);

    const requestLinkToReaction = (patreonPost) => {
        setCurrentRequestedPatreonPostToLink(patreonPost);
        setIsLibraryDialogOpen(true);
    };

    const getPosts = useCallback(() => {
        if (rnryUserRecordToManage && rnryUserRecordToManage.rnryUserRecord && !rnryUserRecordToManage.rnryUserRecord.hasTnsAuthAccount) {
            return (
                <Box sx={{ display: 'flex', fontStyle: 'italic', mt: 5, justifyContent: 'center' }}>
                    <Typography>{'No access to Patreon posts for this user'}</Typography>
                </Box>
            );
        } else if (isLoading) {
            return (
                <Box sx={classes.loadingContainer}>
                    <CircularProgress sx={{ my: 1 }} />
                    <Typography sx={{ my: 1 }} >{'LOADING . . .'}</Typography>
                </Box>
            );
        } else if (filteredPosts && filteredPosts.length > 0) {
            return filteredPosts.map(post => <PatreonPost key={post.id} patreonPost={post} requestLinkToReaction={requestLinkToReaction} />);
        } else {
            return (
                <Box sx={{ display: 'flex', fontStyle: 'italic', mt: 5, justifyContent: 'center' }}>
                    <Typography>{'No matching posts found'}</Typography>
                </Box>
            );
        }
    }, [filteredPosts, isLoading, rnryUserRecordToManage]);

    return (
        <Box sx={classes.pageContainer}>
            <ReactionLibraryDialog isOpen={isLibraryDialogOpen} setIsOpen={setIsLibraryDialogOpen} currentRequestedPatreonPostToLink={currentRequestedPatreonPostToLink} />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 0, m: 0 }}>
                <Typography sx={{ m: 3 }}>{'Patreon Posts'}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip title={'Reload posts from Patreon'} enterDelay={500}>
                    <IconButton sx={{ mr: 2 }} onClick={getPatreonPosts}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={classes.pageContent}>
                <Container>
                    <Stack direction={'row'}>
                        <SearchBoxHeader sx={{ flexGrow: 1 }} searchText={searchText} setSearchText={setSearchText} minLength={minSearchTextLength} />
                        <FormControlLabel sx={{ ml: 10 }} label={<Typography sx={{ fontSize: '0.9rem' }} >{'Show linked posts'}</Typography>} control={<Switch checked={showLinkedPosts} onChange={() => setShowLinkedPosts(!showLinkedPosts)} size={'small'} />} />
                    </Stack>
                    {getPosts()}
                </Container>
            </Box>
        </Box>
    );
};