import { combineReducers } from 'redux';
import { reducer as tmdbStore } from './tmdbStore/reducer';
import { reducer as authStore } from './authStore/reducer';
import { reducer as patreonStore } from './patreonStore/reducer';
import { reducer as recordStore } from './recordStore/reducer';
import { reducer as rnryStore } from './rnryStore/reducer';

export const createRootReducer = () => combineReducers({
    tmdbStore,
    authStore,
    patreonStore,
    recordStore,
    rnryStore
});