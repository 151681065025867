import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as constants from '../../r/constants';
import * as recordStore from '../../r/recordStore';
import * as rnryStore from '../../r/rnryStore';
import * as patreonStore from '../../r/patreonStore';
import LaunchIcon from '@mui/icons-material/Launch';


export const PatreonProfileInfo = () => {

    const dispatch = useDispatch();

    const patreonUserData = useSelector(state => rnryStore.selectors.selectPatreonUserData(state));
    const patreonCampaignData = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryPatreonCampaignData));
    const patreonTierData = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryPatreonTierData));
    const refreshPatreonData = useCallback(() => dispatch(patreonStore.actionCreators.refreshPatreonUserInfo()), [dispatch]);

    return (
        <Card raised sx={{ mt: 5 }}>
            <CardHeader title={'Patreon Profile'} />
            <CardContent>
                {patreonUserData &&
                    <Box>
                        <Paper sx={{ p: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            <Avatar sx={{ mx: 3, my: 0.5, width: 48, height: 48 }} alt='Patreon Thumb' src={patreonUserData.thumbUrl} />
                            <Typography sx={{ mr: 3, color: 'gray' }}>{`[${patreonUserData.patreonUserId}]`}</Typography>
                            <Typography sx={{ mr: 2 }}>{patreonUserData.firstName}</Typography>
                            <Typography sx={{ mr: 2 }}>{patreonUserData.lastName}</Typography>
                        </Paper>
                        
                            <Typography sx={{ mt: 3, typography: 'h6' }} >{'Your Campaign'}</Typography>
                            {patreonCampaignData && patreonCampaignData.length > 0 &&
                                <React.Fragment>
                                <Paper sx={{ m: 1, p: 1}}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Typography sx={{ mx: 3, color: 'gray' }}>{`[${patreonCampaignData[0].patreonCampaignId}]`}</Typography>
                                            <Typography sx={{ mr: 2 }}>{patreonCampaignData[0].vanity}</Typography>
                                            <IconButton component={RouterLink} to={patreonCampaignData[0].url} ><LaunchIcon /></IconButton>
                                        </Box>
                                        <Typography sx={{ mx: 3, fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: patreonCampaignData[0].summary }}></Typography>
                                    </Box>
                                    </Paper>
                                    <Typography sx={{ mx: 3, mt: 3, mb: 1 }}>{'Campaign Tiers'}</Typography>
                                    {patreonTierData.length > 0 &&
                                        patreonTierData.map(tier => (
                                            <Paper key={tier.id} sx={{ mx: 3, py: 1 }} >
                                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                    <Typography sx={{ mx: 3, color: 'gray' }}>{`[${tier.patreonTierId}]`}</Typography>
                                                    <Typography sx={{ mr: 2 }}>{tier.title}</Typography>
                                                </Box>
                                                <Typography sx={{ mx: 3, mt: 1, fontStyle: 'italic' }}>{tier.description}</Typography>
                                            </Paper>
                                        ))
                                    }
                                </React.Fragment>
                            }
                            {(!patreonCampaignData || patreonCampaignData.length == 0) &&
                                <Typography sx={{ mt: 2, fontStyle: 'italic' }} >{'No campaign data found'}</Typography>
                            }
                        
                    </Box>
                }
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }} >
                <Button onClick={refreshPatreonData}>{'Refresh'}</Button>
            </CardActions>
        </Card>
    );
}