import React, { useCallback, useState } from 'react';
import * as dayjs from 'dayjs';
import * as constants from '../../r/constants';
import * as recordStore from '../../r/recordStore';
import * as rnryStore from '../../r/rnryStore';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import FullLengthIcon from '@mui/icons-material/HourglassFull';
import EditedIcon from '@mui/icons-material/ContentCut';
import { useSelector, useDispatch } from 'react-redux';

const classes = {
    noReaction: {
        mt: 1,
        height: '73px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const AddReactionMediaButton = ({
    libraryExpandedRecord,
    rnryReactionType,
    openEditor
}) => {

    const dispatch = useDispatch();

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const reactionMedias = useSelector(state => rnryStore.selectors.selectReactionLibraryMetadataMediaRecordsForMetadataMasterId(state, libraryExpandedRecord.metadata.id));
    const addReactionMediaToLibrary = useCallback(record => dispatch(recordStore.actionCreators.createRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, record, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);
    const updateReactionMediaInLibrary = useCallback(record => dispatch(recordStore.actionCreators.updateRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, record, true, rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);

    const handleAddReactionMedia = () => {
        const standardEdition = libraryExpandedRecord.metadata.editions.find(x => x.metadataMediaEditionType === constants.rnryEditionTypes.standard);
        if (!standardEdition) {
            throw new Error('Edition record is missing for selected title');
        }
        var existingRecord = reactionMedias.find(x => x.rnryReactionType === rnryReactionType);
        if (existingRecord) {
            existingRecord.isFirstTimeWatch = true;
            existingRecord.isFree = true;
            existingRecord.isBlurred = true;
            existingRecord.patreonPostId = '';
            existingRecord.patreonPostUrl = '';
            existingRecord.reactionVideoHost = 'youtube';
            existingRecord.reactionVideoUrl = '';
            existingRecord.reactionDate = dayjs().format('YYYY-MM-DD');
            updateReactionMediaInLibrary(existingRecord);
        }
        else {
            const newMediaRecord = {
                rnryMetadataMasterType: libraryExpandedRecord.metadata.rnryMetadataType,
                rnryMetadataMasterId: libraryExpandedRecord.metadata.id,
                rnryMetadataMediaType: standardEdition.rnryMetadataType,
                rnryMetadataMediaId: standardEdition.id,
                rnryReactionLibraryMetadataMasterId: libraryExpandedRecord.id,
                rnryUserId: libraryExpandedRecord.rnryUserId,
                rnryReactionType: rnryReactionType,
                isFirstTimeWatch: true,
                isFree: true,
                isBlurred: true,
                patreonPostId: '',
                patreonPostUrl: '',
                reactionVideoHost: 'youtube',
                reactionVideoUrl: '',
                reactionStarRatingAverage: 0,
                reactionStarRatingCount: 0,
                overview: '',
                reactionDate: dayjs().format('YYYY-MM-DD')
            }
            addReactionMediaToLibrary(newMediaRecord);
        }
        //openEditor();
    }

    const tooltip = `Add new ${rnryReactionType === constants.rnryReactionTypes.fullLength ? 'full length' : 'edited'} reaction`;

    return (
        <DelayedTooltip title={tooltip}>
            <Button onClick={handleAddReactionMedia} sx={classes.noReaction} color={'faintButton'} variant={'outlined'} >
                {rnryReactionType === constants.rnryReactionTypes.fullLength ? <FullLengthIcon /> : <EditedIcon />}
                <AddIcon />
            </Button>
        </DelayedTooltip>
    );

};