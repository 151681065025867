import React, { useCallback } from 'react';
import * as tmdbStore from '../../r/tmdbStore';
import * as recordStore from '../../r/recordStore';
import * as authStore from '../../r/authStore';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as constants from '../../r/constants';
import { ReactionMediaMiniDetails } from './ReactionMediaMiniDetails';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarBorder';
import Tooltip from '@mui/material/Tooltip';

const classes = {
    posterBoxBorder: {
        border: 2,
        borderColor: 'grey.800',
        textDecoration: 'none',
        borderRadius: '10px',
        margin: '12px',
        backgroundColor: 'grey.900',
        boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none',
        position: 'relative'
    },
    posterBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        height: '530px',
        margin: 2
    },
    posterBoxTitle: {
        marginTop: 2,
        fontSize: 'body2.fontSize',
        color: 'common.white',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    posterBoxYear: {
        marginTop: 0,
        fontSize: 'body2.fontSize',
        color: 'grey.500',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    bookmarkIcon: {
        height: '32px',
        width: '32px'
    },
    bookmarkIconBox: {
        width: '40px',
        height: '40px',
        backgroundColor: '#831c1c',
        color: 'white',
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cornerRadius: '0 10px 0 10px'
    },
    reactionDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        my: 1
    },
    reactionDetailsHeader: {
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px rgb(255,255,255,0.5) dashed',
        fontStyle: 'italic',
        mb: '3px'
    }
}

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const ReactionLibraryMediaContainer = ({ libraryRecord }) => {

    const dispatch = useDispatch();

    const tmdbConfiguration = useSelector(state => tmdbStore.selectors.selectTmdbConfiguration(state));
    const addMetadataToLibrary = useCallback(record => dispatch(recordStore.actionCreators.createRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster, record)), [dispatch]);
    const removeLibraryMovie = useCallback(record => dispatch(recordStore.actionCreators.deleteRecord(constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster, record)), [dispatch]);
    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const tnsUserId = useSelector(state => authStore.selectors.selectTnsUserId(state));
    const reactionLibraryMovies = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster));

    const getImage = useCallback(() => {
        let src = '/i/no-image.jpg';
        let alt = 'Missing';
        if (tmdbConfiguration.images) {
            src = `${tmdbConfiguration.images.secure_base_url}${tmdbConfiguration.images.poster_sizes[4]}${libraryRecord.metadata.tmdbPosterPath}`;
            alt = libraryRecord.metadata.displayTitle;
        }
        return <img width={200} height={300} src={src} alt={alt} loading={'lazy'} />
    }, [libraryRecord, tmdbConfiguration]);

    const removeFromLibrary = useCallback(metadata => {
        if (isLoggedIn) {
            const libraryMovie = metadata && reactionLibraryMovies.find(lib => lib.rnryMetadataMasterId === metadata.id);
            if (libraryMovie) {
                removeLibraryMovie(libraryMovie);
            }
        }
    }, [isLoggedIn, reactionLibraryMovies, removeLibraryMovie]);

    const addToLibrary = useCallback(metadata => {
        if (isLoggedIn) {
            const userLib = {
                rnryMetadataMasterType: metadata.rnryMetadataType,
                rnryMetadataMasterId: metadata.id,
                tnsUserId: tnsUserId
            }
            addMetadataToLibrary(userLib);
        }
    }, [addMetadataToLibrary, isLoggedIn, tnsUserId]);

    const getLibraryIcon = libraryRecord => {
        const metadataMovie = libraryRecord.metadata;
        const isIn = metadataMovie && reactionLibraryMovies.some(lib => lib.rnryMetadataMasterId === metadataMovie.id);
        const tooltip = isIn ? 'Remove from library' : 'Add to library';
        const starIcon = isIn ? <StarIcon sx={classes.bookmarkIcon} /> : <StarOutlineIcon sx={{ ...classes.bookmarkIcon, color: 'rgb(255,255,255,0.5)' }} />;
        const icon = (
            <DelayedTooltip title={tooltip}>
                <Box sx={classes.bookmarkIconBox}>
                    <IconButton disableRipple disabled={libraryRecord.rnryReactionMedias.length > 0} onClick={isIn ? () => removeFromLibrary(metadataMovie) : () => addToLibrary(metadataMovie)}>
                        {starIcon}
                    </IconButton>
                </Box>
            </DelayedTooltip>
        );
        return icon;
    };

    return (libraryRecord && libraryRecord.metadata &&
        <Box sx={classes.posterBoxBorder}>
            <Box sx={classes.posterBox}>
                {getImage(libraryRecord)}
                <Typography sx={classes.posterBoxTitle}>{libraryRecord.metadata.displayTitle}</Typography>
                {libraryRecord.metadata.releaseDate &&
                    <Typography sx={classes.posterBoxYear}>{libraryRecord.metadata.releaseDate.substring(0, 4)}</Typography>
                }
                <Box sx={{ flexGrow: 1 }} />
                <ReactionMediaMiniDetails libraryExpandedRecord={libraryRecord} reactionMedia={libraryRecord.rnryReactionMedias.find(x => x.rnryReactionType === constants.rnryReactionTypes.fullLength)} rnryReactionType={constants.rnryReactionTypes.fullLength} />
                <ReactionMediaMiniDetails libraryExpandedRecord={libraryRecord} reactionMedia={libraryRecord.rnryReactionMedias.find(x => x.rnryReactionType === constants.rnryReactionTypes.edited)} rnryReactionType={constants.rnryReactionTypes.edited} />
            </Box>
            {getLibraryIcon(libraryRecord)}
        </Box>
    );
};