import { createSelector } from 'reselect';

const selectIsLoggedIn = state => state.authStore.isLoggedIn;
const selectTnsUserId = state => state.authStore.tnsUserId;
const selectUserFirstName = state => state.authStore.userFirstName;
const selectUserLastName = state => state.authStore.userLastName;
const selectUserEmail = state => state.authStore.userEmail;
const selectUserPicture = state => state.authStore.userPicture;
const selectAccessToken = state => state.authStore.accessToken;
const selectIdToken = state => state.authStore.idToken;
const selectClaims = state => state.authStore.claims;
const selectProviders = state => state.authStore.providers;
const selectHasCheckedUserInfo = state => state.authStore.hasCheckedUserInfo;
const selectSubscription = state => state.authStore.subscription;
const selectIsProfileConfirmed = state => state.authStore.isProfileConfirmed;

const selectRnryUserId = createSelector(
    [
        selectClaims
    ],
    claims => {
        return claims.find(x => x.claimType === 'rnrysub')?.claimValue ?? '';
    }
);

const selectIsAdminUser = createSelector(
    [
        selectClaims
    ],
    claims => {
        const role = claims.find(x => x.claimType === 'utyp')?.claimValue ?? '';
        return role === 'tns.admin';
    }
);

export const selectors = {
    selectIsLoggedIn,
    selectTnsUserId,
    selectUserFirstName,
    selectUserLastName,
    selectUserEmail,
    selectUserPicture,
    selectAccessToken,
    selectIdToken,
    selectClaims,
    selectProviders,
    selectHasCheckedUserInfo,
    selectSubscription,
    selectIsProfileConfirmed,
    selectRnryUserId,
    selectIsAdminUser
}