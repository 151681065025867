import { createStore, applyMiddleware, compose } from 'redux';
import { createRootReducer } from './reducers';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { runSagaWatchers } from './sagaWatchers';
import * as constants from './constants';

const recordTypes = [
    {
        recordTypeName: 'rnryMetadataMovie',
        urlEndpoint: 'movies'
    },
    {
        recordTypeName: 'rnryMetadataMovieEdition',
        urlEndpoint: 'movie-editions'
    },
    {
        recordTypeName: 'rnryMetadataShow',
        urlEndpoint: 'shows'
    },
    {
        recordTypeName: 'rnryMetadataSeason',
        urlEndpoint: 'seasons'
    },
    {
        recordTypeName: 'rnryMetadataEpisode',
        urlEndpoint: 'episodes'
    },
    {
        recordTypeName: 'rnryMetadataEpisodeEdition',
        urlEndpoint: 'episode-editions'
    },
    {
        recordTypeName: 'rnryReactionLibraryMetadataMaster',
        urlEndpoint: 'u/{userId}/reactions'
    },
    {
        recordTypeName: 'rnryReactionLibraryMetadataMedia',
        urlEndpoint: 'u/{userId}/reaction-medias',
        urlEndpointRnryUser: 'ru/{rnryUserId}/reaction-medias'
    },
    {
        recordTypeName: 'rnryUserLibraryMetadataMaster',
        urlEndpoint: 'u/{userId}/library-items'
    },
    {
        recordTypeName: 'rnryUserLibraryReactionWatch',
        urlEndpoint: 'u/{userId}/reaction-watches'
    },
    {
        recordTypeName: 'rnryPatreonUserData',
        urlEndpoint: 'u/{userId}/patreon-user-data'
    },
    {
        recordTypeName: 'rnryPatreonCampaignData',
        urlEndpoint: 'u/{userId}/patreon-campaign-data'
    },
    {
        recordTypeName: 'rnryPatreonTierData',
        urlEndpoint: 'u/{userId}/patreon-tier-data'
    },
    {
        recordTypeName: 'rnryReactorExpandedDto',
        urlEndpoint: 'reactors'
    },
    {
        recordTypeName: constants.rnryRecordTypes.rnryUserReactor,
        urlEndpoint: 'u/{userId}/reactors'
    }
]

export const configureStore = () => {

    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const sagaMiddleware = createSagaMiddleware();
    const middleware = [
        thunk,
        sagaMiddleware
    ];

    const rootReducer = createRootReducer();
    const initialState = {
        recordStore: {
            recordTypes
        }
    };

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    runSagaWatchers(sagaMiddleware);

    return store;
}