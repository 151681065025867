import React, { useCallback } from 'react';
import * as authStore from '../../r/authStore';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UserLoggedOutIcon from '@mui/icons-material/PersonOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';

const classes = {
    outerContainer: {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'center',
        overflowY: 'auto'
    },
    progress: {
        color: '#ffffff',
        marginTop: '48px'
    },
    authUserPageContainer: {
        display: 'flex',
        flexGrow: '1'
    },
    container: {
        mt: 4,
        display: 'flex',
        flexDirection: 'column'
    },
    loginContainer: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 5
    },
    loginText: {
        marginBottom: '24px',
        typography: 'h4'
    },
    loginButton: {
        color: 'white',
        backgroundColor: 'grey',
        '&:hover': {
            backgroundColor: 'black'
        }
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '2px black solid',
        borderRadius: '10px',
        p: 2,
        backgroundColor: '#0a410c'
    }
};

export const AuthUserPagePresenter = ({ children, authLoginPageControl }) => {

    const dispatch = useDispatch();

    const child = React.cloneElement(children, { sx: classes.authUserPageContainer });

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const hasCheckedUserInfo = useSelector(state => authStore.selectors.selectHasCheckedUserInfo(state));

    const loginUser = useCallback(() => dispatch(authStore.actionCreators.loginUser('patreon')), [dispatch]);

    return isLoggedIn ? (
        child
    ) : (
        <Box sx={classes.outerContainer}>
            {hasCheckedUserInfo &&
                (authLoginPageControl || (
                    <Container>
                        <Paper sx={classes.container} elevation={2}>
                            <Box sx={{ alignSelf: 'center', mx: 4, mt: 4, mb: 0, display: 'flex', borderRadius: '3px', p: '1px', boxShadow: '2px 2px 2px 1px #121212', border: '1px #373737 solid' }} >
                                <img width={500} src={'/i/logo-reactionairy.png'} alt={'Reactionairy Logo'} />
                            </Box>
                            {/*<Box sx={{ alignSelf: 'center' }}>*/}
                            {/*    <Typography sx={{ typography: 'h3' }}>{'Welcome to Reactionairy!'}</Typography>*/}
                            {/*</Box>*/}
                            <List
                                sx={{
                                    listStyleType: 'disc',
                                    pl: 2,
                                    m: 4,
                                    '& .MuiListItem-root': {
                                        display: 'list-item'
                                    },
                                }}>
                                <ListItem sx={{ typography: 'h5' }}>
                                    <Typography sx={{ typography: 'h5' }}>{'Add your favorite movies and TV shows to your library to discover popular reactions.'}</Typography>
                                </ListItem>
                                <ListItem sx={{ typography: 'h5' }}>
                                    <Typography sx={{ typography: 'h5' }}>{'Search reactions from your favorite reactors.'}</Typography>
                                </ListItem>
                                <ListItem sx={{ typography: 'h5' }}>
                                    <Typography sx={{ typography: 'h5' }}>{`Track watch status across your favorite reactors so you know which ones you've seen.`}</Typography>
                                </ListItem>
                            </List>
                            {!isLoggedIn &&
                                <Box sx={classes.loginContainer}>
                                    <Box sx={classes.loginBox}>
                                        <Typography sx={classes.loginText}>Please log in to gain access!</Typography>
                                        <Button variant={'contained'} onClick={loginUser} sx={classes.loginButton} size={'large'} startIcon={<UserLoggedOutIcon />}>
                                            {'Log In'}
                                        </Button>
                                    </Box>
                                    {/*<Typography sx={{ mx: 8, mt: 4, mb: 2 }}>*/}
                                    {/*    {'Reactionairy links to your Patreon account to allow seamless integration with the reactors that you follow. After you log in with your Patreon account, you will see a screen like this requesting access to certain data. You must allow access in order to proceed. No other data, such as your login information, is shared with Reactionairy. You can revoke access at any time by going to '}*/}
                                    {/*    <Link underline={'none'} target={'_blank'} rel={'noreferrer'} href={'https://www.patreon.com/settings/apps'} >*/}
                                    {/*        {'https://www.patreon.com/settings/apps'}*/}
                                    {/*    </Link>*/}
                                    {/*    {'.'}*/}
                                    {/*</Typography>*/}
                                    <Typography sx={{ mx: 8, mt: 4, mb: 2 }}>
                                        {'Reactionairy links to your Patreon account to allow seamless integration with the reactors that you follow. After you log in with your Patreon account, you will see a screen like this requesting access to certain data. You must allow access in order to proceed. No other data, such as your login information, is shared with Reactionairy.'}
                                    </Typography>
                                    <Box sx={{ mx: 12, my: 2, display: 'flex' }} >
                                        <img src={'/i/patreon-oauth.png'} alt={'PatreonOAuthScreen'} />
                                        <List
                                            sx={{
                                                listStyleType: 'disc',
                                                pl: 0,
                                                mx: 8,
                                                my: 0,
                                                '& .MuiListItem-root': {
                                                    display: 'list-item'
                                                },
                                            }}>
                                            <ListItem>
                                                <Typography>
                                                    <Box sx={{ mr: 1, fontWeight: 'bold', fontStyle: 'italic' }} component={'span'}>
                                                        {'View your public profile:'}
                                                    </Box>
                                                    {'Access basic public information such as your user name and avatar.'}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography>
                                                    <Box sx={{ mr: 1, fontWeight: 'bold', fontStyle: 'italic' }} component={'span'}>
                                                        {'View your pledges:'}
                                                    </Box>
                                                    {`Access basic public information for the reactors that you subscribe to. If you are a reactor, this does NOT include your subscribers' information.`}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography>
                                                    <Box sx={{ mr: 1, fontWeight: 'bold', fontStyle: 'italic' }} component={'span'}>
                                                        {'View public information about your page:'}
                                                    </Box>
                                                    {'If you are a reactor, access basic public information about your campaign to allow you to map your posts to the corresponding movie or TV show.'}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography>
                                                    <Box sx={{ mr: 1, fontWeight: 'bold', fontStyle: 'italic' }} component={'span'}>
                                                        {'View your email address:'}
                                                    </Box>
                                                    {`Access your email address registered with Patreon in order to validate and link your account to Reactionairy. If you are a reactor, this does NOT include your subscribers' email addresses.`}
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Box>
                            }
                        </Paper>
                    </Container>
                ))}
            {!hasCheckedUserInfo && <CircularProgress sx={classes.progress} />}
        </Box>
    );
};