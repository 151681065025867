import { put, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as recordStore from '../recordStore';
import * as authStore from '../authStore';
import * as patreonStore from './';
import * as constants from '../constants';

function* watchRefreshPatreonMemberships() {
    yield takeEvery(actionTypes.refreshPatreonMembershipsSaga, refreshPatreonMembershipsAsync);
}

function* refreshPatreonMembershipsAsync() {
    const url = '/api/patreon/refresh-membership-data';
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(recordStore.actionCreators.getRecords(constants.rnryRecordTypes.rnryPatreonUserData));
        yield put(recordStore.actionCreators.getRecords(constants.rnryRecordTypes.rnryPatreonCampaignData));
        yield put(recordStore.actionCreators.getRecords(constants.rnryRecordTypes.rnryPatreonTierData));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

function* watchRefreshPatreonUserInfo() {
    yield takeEvery(actionTypes.refreshPatreonUserInfoSaga, refreshPatreonUserInfoAsync);
}

function* refreshPatreonUserInfoAsync() {
    const url = '/api/patreon/refresh-user-data';
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(recordStore.actionCreators.getRecords(constants.rnryRecordTypes.rnryPatreonUserData));
        yield put(recordStore.actionCreators.getRecords(constants.rnryRecordTypes.rnryPatreonCampaignData));
        yield put(recordStore.actionCreators.getRecords(constants.rnryRecordTypes.rnryPatreonTierData));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

function* watchGetMembershipCreatorRecords() {
    yield takeEvery(actionTypes.getMembershipCreatorRecordsSaga, getMembershipCreatorRecordsAsync);
}

function* getMembershipCreatorRecordsAsync() {
    const url = '/api/patreon/membership-data';
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(patreonStore.actionCreators.receiveMembershipCreatorRecords(result.data));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

function* watchGetPatreonPosts() {
    yield takeEvery(actionTypes.getPatreonPostsSaga, getPatreonPostsAsync);
}

function* getPatreonPostsAsync() {
    yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(true));
    const url = '/api/patreon/posts';
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(false));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(patreonStore.actionCreators.receivePatreonPosts(result.data));
        yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(false));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

function* watchGetPatreonPostsForRnryUserId() {
    yield takeEvery(actionTypes.getPatreonPostsForRnryUserIdSaga, getPatreonPostsForRnryUserIdAsync);
}

function* getPatreonPostsForRnryUserIdAsync(action) {
    yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(true));
    const url = `/api/patreon/ru/${action.rnryUserId}/posts`;
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(false));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(patreonStore.actionCreators.receivePatreonPosts(result.data));
        yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(false));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

function* watchGetPatreonPostsForTnsUserId() {
    yield takeEvery(actionTypes.getPatreonPostsForTnsUserIdSaga, getPatreonPostsForTnsUserIdAsync);
}

function* getPatreonPostsForTnsUserIdAsync(action) {
    yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(true));
    const url = `/api/patreon/u/${action.tnsUserId}/posts`;
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(false));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(patreonStore.actionCreators.receivePatreonPosts(result.data));
        yield put(patreonStore.actionCreators.setIsLoadingPatreonPosts(false));
        //yield put(alert.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and tnsUserId=${result.data.tnsUserId}`, 'info'));
    }
}

export const sagaWatchers = sagaMiddleware => {
    sagaMiddleware.run(watchRefreshPatreonMemberships);
    sagaMiddleware.run(watchRefreshPatreonUserInfo);
    sagaMiddleware.run(watchGetMembershipCreatorRecords);
    sagaMiddleware.run(watchGetPatreonPosts);
    sagaMiddleware.run(watchGetPatreonPostsForRnryUserId);
    sagaMiddleware.run(watchGetPatreonPostsForTnsUserId);
}