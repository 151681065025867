import * as actionTypes from './actionTypes';

export const actionCreators = {

    loginUser: (providerCode) => dispatch => {
        dispatch({ type: actionTypes.loginUserSaga, providerCode });
    },

    logoutUser: () => dispatch => {
        dispatch({ type: actionTypes.logoutUserSaga });
    },

    getAuthUserInfo: () => dispatch => {
        dispatch({ type: actionTypes.getAuthUserInfoSaga });
    },

    setHasCheckedUserInfo: (hasCheckedUserInfo) => dispatch => {
        dispatch({ type: actionTypes.setHasCheckedUserInfo, hasCheckedUserInfo });
    },

    receiveAuthUserInfo: (tnsUserInfo) => dispatch => {
        dispatch({ type: actionTypes.receiveAuthUserInfo, tnsUserInfo });
    },

    receiveProfileInfo: (tnsUserInfo) => dispatch => {
        dispatch({ type: actionTypes.receiveProfileInfo, tnsUserInfo });
    },

    setIsSaving: isSaving => dispatch => {
        dispatch({ type: actionTypes.setIsSaving, isSaving });
    },

    updateAuthUserInfo: record => dispatch => {
        dispatch({ type: actionTypes.updateAuthUserInfoSaga, record});
    },

    cancelAutoRenewalSubscription: () => dispatch => {
        dispatch({ type: actionTypes.cancelAutoRenewalSubscriptionSaga});
    }
    
}