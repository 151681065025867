import * as actionTypes from './actionTypes';

export const actionCreators = {
    addItemToLibrary: (rnryLibraryType, rnryMetadataType, tmdb, rnryUserId) => dispatch => {
        dispatch({ type: actionTypes.addItemToLibrarySaga, rnryLibraryType, rnryMetadataType, tmdb, rnryUserId });
    },

    addItemMediaToLibrary: (rnryLibraryType, rnryMetadataType, record) => dispatch => {
        dispatch({ type: actionTypes.addItemToLibrarySaga, rnryLibraryType, rnryMetadataType, record });
    },

    setIsConfirmProfileOpen: isOpen => dispatch => {
        dispatch({ type: actionTypes.setIsConfirmProfileOpen, isOpen });
    },

    getReactorReactions: rnryUserId => dispatch => {
        dispatch({ type: actionTypes.getReactorReactionsSaga, rnryUserId });
    },

    getMovieReactions: rnryMetadataMasterId => dispatch => {
        dispatch({ type: actionTypes.getMovieReactionsSaga, rnryMetadataMasterId });
    },

    setReactorRnryUserIdToManage: rnryUserId => dispatch => {
        dispatch({ type: actionTypes.setReactorRnryUserIdToManage, rnryUserId });
    }
};
