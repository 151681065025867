import { useEffect, useRef, useState, useCallback } from 'react';

export const useInterval = (callback, interval, startCondition, stopCondition) => {

    const savedCallback = useRef();
    const [id, setId] = useState(0);

    const idRef = useRef(id);

    useEffect(() => {
        idRef.current = id;
    }, [id]);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const tick = useCallback(() => {
        console.info(`Running interval ${idRef.current} callback`);
        savedCallback.current();
    }, [savedCallback, idRef]);

    useEffect(() => {
        if (interval !== null) {
            if (startCondition && id === 0) {
                console.info('Setting interval');
                let newId = window.setInterval(tick, interval);
                console.info(`Set interval ${newId}`);
                setId(newId);
            }
            if (stopCondition && id !== 0) {
                console.info(`Clearing interval ${id}`);
                window.clearInterval(id);
                setId(0);
            }
        }
    }, [interval, startCondition, stopCondition, id, tick]);
}