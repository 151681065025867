import { sagaWatchers as tmdbStore } from './tmdbStore/sagas';
import { sagaWatchers as authStore } from './authStore/sagas';
import { sagaWatchers as patreonStore } from './patreonStore/sagas';
import { sagaWatchers as recordStore } from './recordStore/sagas';
import { sagaWatchers as rnryStore } from './rnryStore/sagas';

export const runSagaWatchers = (sagaMiddleware) => {
    tmdbStore(sagaMiddleware);
    authStore(sagaMiddleware);
    patreonStore(sagaMiddleware);
    recordStore(sagaMiddleware);
    rnryStore(sagaMiddleware);
}