import { put, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as recordStore from './';
import * as authStore from '../authStore';
//import * as logger from '../logger';
import { selectors } from './selectors';
//import { logEventLevels } from '../logger/logEventLevels';

export const sagaWatchers = sagaMiddleware => {
    sagaMiddleware.run(watchGetRecords);
    sagaMiddleware.run(watchGetAllRecords);
    sagaMiddleware.run(watchUpdateRecord);
    sagaMiddleware.run(watchCreateRecord);
    sagaMiddleware.run(watchDeleteRecord);
    //sagaMiddleware.run(watchDeprecateRecord);
    sagaMiddleware.run(watchGetRecordById);
};

function* watchGetRecords() {
    yield takeEvery(actionTypes.getRecordsSaga, getRecords);
}

function* getRecords(action) {
    const recordTypes = yield select(selectors.selectRecordTypes);
    const userId = yield select(authStore.selectors.selectTnsUserId);
    yield put(recordStore.actionCreators.beginLoadingRecords(action.recordType));
    const url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpoint.replace('{userId}', userId)}`;
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        //        yield put(alert.actionCreators.pushAlert(`Load ${action.recordType} call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(recordStore.actionCreators.receiveRecords(action.recordType, result.data));
        //        yield put(alert.actionCreators.pushAlert(`Loaded: ${action.recordType}`, 'info'));
        //        yield put(logger.actionCreators.logMessage('verbose', `Loaded: ${action.recordType}`))
    }
}

function* watchGetAllRecords() {
    yield takeEvery(actionTypes.getAllRecordsSaga, getAllRecords);
}

function* getAllRecords(action) {
    const recordTypes = yield select(selectors.selectRecordTypes);
    const userId = yield select(authStore.selectors.selectTnsUserId);
    console.info(`userId: ${userId}`);
    //const isLogged = yield select(authStore.selectors.selectIsLoggedIn);
    //const userFirstName = yield select(authStore.selectors.selectUserFirstName);
    //const sel = authStore.selectors;
    yield put(recordStore.actionCreators.beginLoadingRecords(action.recordType));
    const url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpoint.replace('{userId}', userId)}?includeDeprecatedItems=true`;
    const result = yield callApi(url);
    if (result.isAxiosError) {
        //        yield put(logger.actionCreators.logMessage(logEventLevels.error, 'Load {RecordType} call failed with {StatusCode}', [action.recordType, result.response.status]))
        //        yield put(alert.actionCreators.pushAlert(`Load ${action.recordType} call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(recordStore.actionCreators.receiveRecords(action.recordType, result));
        //        yield put(logger.actionCreators.logMessage(logEventLevels.verbose, 'Loaded: {RecordType}', [action.recordType]))
        //        yield put(alert.actionCreators.pushAlert(`Loaded: ${action.recordType}`, 'info'));
    }
}

function* callApi(url, token) {
    const returnData = [];
    const config = {};
    if (token) {
        config.headers = {
            'tns-continuation-token': token
        };
    }
    const result = yield axios.get(url, config).catch(error => error);
    if (result.isAxiosError) {
        return result;
    } else {
        returnData.push(...result.data);
        if (result.headers['tns-continuation-token']) {
            const result2 = yield callApi(url, result.headers['tns-continuation-token']);
            if (result2.isAxiosError) {
                return result2;
            } else {
                returnData.push(...result2);
            }
        }
        return returnData;
    }
}

function* watchUpdateRecord() {
    yield takeEvery(actionTypes.updateRecordSaga, updateRecord);
}

function* updateRecord(action) {
    const recordTypes = yield select(selectors.selectRecordTypes);
    yield put(recordStore.actionCreators.setIsSaving(action.recordType, true));
    action.record.recordTypeSchemaVersion = 1;
    let url = '';
    if (action.useRnryUserId) {
        url = `/api/${recordTypes.find(x => x.recordTypeName === action.record.recordType).urlEndpointRnryUser.replace('{rnryUserId}', action.rnryUserId)}/${action.record.id}`;
    } else {
        const userId = yield select(authStore.selectors.selectTnsUserId);
        url = `/api/${recordTypes.find(x => x.recordTypeName === action.record.recordType).urlEndpoint.replace('{userId}', userId)}/${action.record.id}`;
    }
    const result = yield axios.post(url, action.record).catch(error => error);
    if (result.isAxiosError) {
        //        yield put(alert.actionCreators.pushAlert(`Update ${action.record.recordType} call failed with ${result.response.status}`, 'error'));
        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
    } else {
        //        yield put(alert.actionCreators.pushAlert(`Updated record: ${action.record.recordType} \\ ${action.record.id}`, 'info'));
        yield put(recordStore.actionCreators.updateRecordSuccess(action.recordType, result.data));
        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
    }
}

function* watchCreateRecord() {
    yield takeEvery(actionTypes.createRecordSaga, createRecord);
}

function* createRecord(action) {
    const recordTypes = yield select(selectors.selectRecordTypes);
    yield put(recordStore.actionCreators.setIsSaving(action.recordType, true));
    let url = '';
    if (action.useRnryUserId) {
        url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpointRnryUser.replace('{rnryUserId}', action.rnryUserId)}`;
    } else {
        const userId = yield select(authStore.selectors.selectTnsUserId);
        url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpoint.replace('{userId}', userId)}`;
    }
    const result = yield axios.post(url, action.record).catch(error => error);
    if (result.isAxiosError) {
        //        yield put(alert.actionCreators.pushAlert(`Create ${action.recordType} call failed with ${result.response.status}`, 'error'));
        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
    } else {
        //        yield put(alert.actionCreators.pushAlert(`Created record: ${action.recordType}`, 'info'));
        yield put(recordStore.actionCreators.createRecordSuccess(action.recordType, action.tempId, result.data));
        if (action.selectNewRecord && result.data && result.data.id) {
            yield put(recordStore.actionCreators.setSelectedId(action.recordType, result.data.id));
        }
        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
        yield put(recordStore.actionCreators.createRecordSuccessCompleted(action.recordType, result.data));
    }
}

function* watchDeleteRecord() {
    yield takeEvery(actionTypes.deleteRecordSaga, deleteRecord);
}

function* deleteRecord(action) {
    const recordTypes = yield select(selectors.selectRecordTypes);
    yield put(recordStore.actionCreators.setIsSaving(action.recordType, true));
    let url = '';
    if (action.useRnryUserId) {
        url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpointRnryUser.replace('{rnryUserId}', action.rnryUserId)}/${action.record.id}`;
    } else {
        const userId = yield select(authStore.selectors.selectTnsUserId);
        url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpoint.replace('{userId}', userId)}/${action.record.id}`;
    }
    const config = {};
    config.headers = {
        'If-Match': action.record.eTag
    };
    const result = yield axios.delete(url, config).catch(error => error);
    if (result.isAxiosError) {
        //        yield put(alert.actionCreators.pushAlert(`Delete ${action.recordType} call failed with ${result.response.status}`, 'error'));
        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
    } else {
        //        yield put(alert.actionCreators.pushAlert(`Deleted record: ${action.recordType}`, 'info'));
        yield put(recordStore.actionCreators.deleteRecordSuccess(action.recordType, action.record.id));
        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
    }
}

//function* watchDeprecateRecord() {
//    yield takeEvery(actionTypes.deprecateRecordSaga, deprecateRecord);
//}

//function* deprecateRecord(action) {
//    const recordTypes = yield select(selectors.selectRecordTypes);
//    yield put(recordStore.actionCreators.setIsSaving(action.recordType, true));
//    const url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpoint}/${action.record.id}/archive`
//    const config = {};
//    config.headers = {
//        'If-Match': action.record.eTag
//    };
//    const result = yield axios.patch(url, null, config).catch(error => error);
//    if (result.isAxiosError) {
//        //        yield put(alert.actionCreators.pushAlert(`Archive ${action.recordType} call failed with ${result.response.status}`, 'error'));
//        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
//    } else {
//        //        yield put(alert.actionCreators.pushAlert(`Archived record: ${action.recordType}`, 'info'));
//        action.record.isDeprecated = true;
//        yield put(recordStore.actionCreators.deprecateRecordSuccess(action.recordType, action.record));
//        yield put(recordStore.actionCreators.setIsSaving(action.recordType, false));
//    }
//}

function* watchGetRecordById() {
    yield takeEvery(actionTypes.getRecordByIdSaga, getRecordById);
}

function* getRecordById(action) {
    const recordTypes = yield select(selectors.selectRecordTypes);
    const userId = yield select(authStore.selectors.selectTnsUserId);
    const url = `/api/${recordTypes.find(x => x.recordTypeName === action.recordType).urlEndpoint.replace('{userId}', userId)}/${action.recordId}`;
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        //        yield put(alert.actionCreators.pushAlert(`Load ${action.recordType} call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(recordStore.actionCreators.updateRecordSuccess(action.recordType, result.data));
        //        yield put(alert.actionCreators.pushAlert(`Successfully loaded from SignalR: ${action.recordType}`, 'info'));
    }
}
