import * as actionTypes from './actionTypes';

const initialState = {
    hasCheckedUserInfo: false,
    isLoggedIn: false,
    tnsUserId: '',
    userFirstName: '',
    userLastName: '',
    userFullName: '',
    userEmail: '',
    userPicture: '',
    accessToken: '',
    idToken: '',
    claims: [],
    providers: [],
    subscription: {},
    isProfileConfirmed: false
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case actionTypes.receiveAuthUserInfo:
            return {
                ...state,
                hasCheckedUserInfo: true,
                isLoggedIn: action.tnsUserInfo.isLoggedIn,
                tnsUserId: action.tnsUserInfo.tnsUserId,
                accessToken: action.tnsUserInfo.accessToken,
                idToken: action.tnsUserInfo.idToken,
                claims: action.tnsUserInfo.claims,
                providers: action.tnsUserInfo.providers,
                userFirstName: action.tnsUserInfo.firstName,
                userLastName: action.tnsUserInfo.lastName,
                userFullName: action.tnsUserInfo.name,
                userPicture: action.tnsUserInfo.pictureUri,
                userEmail: action.tnsUserInfo.email,
                //subscription: action.subscriptionInfo,
                isProfileConfirmed: action.tnsUserInfo.isProfileConfirmed
            };

        case actionTypes.setHasCheckedUserInfo:
            return {
                ...state,
                hasCheckedUserInfo: action.hasCheckedUserInfo
            };

        case actionTypes.receiveProfileInfo:
            return {
                ...state,
                userFirstName: action.tnsUserInfo.firstName,
                userLastName: action.tnsUserInfo.lastName,
                userEmail: action.tnsUserInfo.email,
                isProfileConfirmed: action.tnsUserInfo.isProfileConfirmed
            };

        default:
            return state;
    }
};
