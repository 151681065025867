import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

const selectRecordTypes = (state) => state.recordStore.recordTypes;
const selectRecords = (state, recordType) => state.recordStore[recordType].records;
const selectSelectedId = (state, recordType) => state.recordStore[recordType].selectedId;
const selectIsSaving = (state, recordType) => state.recordStore[recordType].isSaving;
const selectServerIdMaps = (state, recordType) => state.recordStore[recordType].serverIdMaps;
const selectIsLoading = (state, recordType) => state.recordStore[recordType].isLoading;
const selectIsLoaded = (state, recordType) => state.recordStore[recordType].isLoaded;

const selectServerIdForNewRecord = createCachedSelector(
    [
        selectServerIdMaps,
        (_state, _recordType, tempId) => tempId
    ],
    (serverIdMaps, tempId) => serverIdMaps[tempId]
)(
    (_state, _recordType, tempId) => tempId
);

const selectSelectedRecord = createSelector(
    [
        selectRecords,
        selectSelectedId
    ],
    (records, id) => records.find(x => x.id === id)
);

const selectRecordById = createCachedSelector(
    [
        selectRecords,
        (_state, _recordType, id) => id
    ],
    (records, id) => records.find(x => x.id === id)
)(
    (_state, _recordType, id) => id
);

const selectRecordsForRnryUserId = createCachedSelector(
    [
        selectRecords,
        (_state, _recordType, rnryUserId) => rnryUserId
    ],
    (records, rnryUserId) => {
        return records.filter(x => x.rnryUserId === rnryUserId);
    }
)(
    (_state, _recordType, rnryUserId) => rnryUserId
);

export const selectors = {
    selectRecordTypes,
    selectRecords,
    selectSelectedId,
    selectIsSaving,
    selectServerIdMaps,
    selectSelectedRecord,
    selectRecordById,
    selectServerIdForNewRecord,
    selectIsLoading,
    selectIsLoaded,
    selectRecordsForRnryUserId
}