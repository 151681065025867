import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import { useSelector, useDispatch } from 'react-redux';
import * as authStore from '../../r/authStore';
import * as rnryStore from '../../r/rnryStore';
import { PatreonProfileInfo } from './PatreonProfileInfo';

export const UserPage = () => {

    const dispatch = useDispatch();

    const userFirstName = useSelector(state => authStore.selectors.selectUserFirstName(state));
    const userLastName = useSelector(state => authStore.selectors.selectUserLastName(state));
    const userEmail = useSelector(state => authStore.selectors.selectUserEmail(state));
    const userId = useSelector(state => authStore.selectors.selectTnsUserId(state));

    //const isConfirmProfileOpen = useSelector(state => rnryStore.selectors.selectIsConfirmProfileOpen(state));
    const setIsConfirmProfileOpen = useCallback(isOpen => dispatch(rnryStore.actionCreators.setIsConfirmProfileOpen(isOpen)), [dispatch]);
    const logout = useCallback(() => dispatch(authStore.actionCreators.logoutUser()), [dispatch]);
    const updateUserProfile = useCallback(record => dispatch(authStore.actionCreators.updateAuthUserInfo(record)), [dispatch]);

    const [tempFirstName, setTempFirstName] = useState(userFirstName);
    const [tempFirstNameError, setTempFirstNameError] = useState(false);
    const [tempFirstNameErrorMessage, setTempFirstNameErrorMessage] = useState('');

    const [tempLastName, setTempLastName] = useState(userLastName);
    const [tempLastNameError, setTempLastNameError] = useState(false);
    const [tempLastNameErrorMessage, setTempLastNameErrorMessage] = useState('');

    const [tempEmail, setTempEmail] = useState(userEmail);
    const [tempEmailError, setTempEmailError] = useState(false);
    const [tempEmailErrorMessage, setTempEmailErrorMessage] = useState('');

    useEffect(() => {
        setTempFirstName(userFirstName);
    }, [userFirstName]);

    useEffect(() => {
        setTempLastName(userLastName);
    }, [userLastName]);

    useEffect(() => {
        setTempEmail(userEmail);
    }, [userEmail]);

    const handleSave = () => {
        updateUserProfile({
            userId: userId,
            firstName: tempFirstName,
            lastName: tempLastName,
            email: tempEmail,
            isProfileConfirmed: true
        });
    }

    const handleChangeFirstName = e => {
        setTempFirstName(e.target.value)
        if (e.target.value.trim().length < 2) {
            setTempFirstNameError(true);
            setTempFirstNameErrorMessage('First name is required')
        } else {
            setTempFirstNameError(false);
            setTempFirstNameErrorMessage('')
        }
    }

    const handleChangeLastName = e => {
        setTempLastName(e.target.value)
        if (e.target.value.trim().length < 2) {
            setTempLastNameError(true);
            setTempLastNameErrorMessage('Last name is required')
        } else {
            setTempLastNameError(false);
            setTempLastNameErrorMessage('')
        }
    }

    const handleChangeEmail = e => {
        setTempEmail(e.target.value)
        const newText = e.target.value.trim();
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
        if (newText.length < 2) {
            setTempEmailError(true);
            setTempEmailErrorMessage('Email is required')
        } else if (!regex.test(newText)) {
            setTempEmailError(true);
            setTempEmailErrorMessage('Invalid email address')
        } else {
            setTempEmailError(false);
            setTempEmailErrorMessage('')
        }
    }

    return (
        <Container maxWidth={'lg'}>
            <Card raised sx={{ mt: 5 }}>
                <CardHeader title={'Reactionairy Profile'} />
                <CardContent>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }} >
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            autoFocus
                            autoComplete={'off'}
                            margin={'normal'}
                            id={'firstName'}
                            label={'First Name'}
                            type={'text'}
                            sx={{ width: '25%', minWidth: 300 }}
                            variant={'standard'}
                            onChange={handleChangeFirstName}
                            value={tempFirstName}
                            error={tempFirstNameError}
                            helperText={tempFirstNameErrorMessage}
                        />
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            autoFocus
                            margin={'normal'}
                            autoComplete={'off'}
                            id={'lastName'}
                            label={'Last Name'}
                            type={'text'}
                            sx={{ width: '25%', minWidth: 300 }}
                            variant={'standard'}
                            onChange={handleChangeLastName}
                            value={tempLastName}
                            error={tempLastNameError}
                            helperText={tempLastNameErrorMessage}
                        />
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            autoFocus
                            margin={'normal'}
                            autoComplete={'off'}
                            id={'email'}
                            label={'Email'}
                            type={'email'}
                            sx={{ width: '25%', minWidth: 300 }}
                            variant={'standard'}
                            onChange={handleChangeEmail}
                            value={tempEmail}
                            error={tempEmailError}
                            helperText={tempEmailErrorMessage}
                        />
                    </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }} >
                    <Button onClick={logout} >{'Logout'}</Button>
                    <Button onClick={() => setIsConfirmProfileOpen(true)} >{'Edit'}</Button>
                </CardActions>
            </Card>
            <PatreonProfileInfo />
        </Container>
    );
}