import React from 'react';
import { NavDrawerShell } from './NavDrawerShell';
import { DataLoader } from './DataLoader';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        faintButton: {
            main: '#909090'
        },
        whiteButton: {
            main: '#FFFFFF'
        }

    }
});

export const RootShell = () => {

    return (
        <ThemeProvider theme={darkTheme}>
            <DataLoader />
            <NavDrawerShell />
        </ThemeProvider>
    );
}