import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import { RootShell } from './c/shell/RootShell';
import { HelpPage } from './c/helpPage/HelpPage';
import { ReactorsPage } from './c/reactorsPage/ReactorsPage';
import { ReactorDetailsPage } from './c/reactorsPage/ReactorDetailsPage';
import { MovieLibraryPage } from './c/movieLibraryPage/MovieLibraryPage';
import { MovieDetailsPage } from './c/movieLibraryPage/MovieDetailsPage';
import { HomePage } from './c/homePage/HomePage';
import { UserPage } from './c/userPage/UserPage';
import { AuthUserPagePresenter } from './c/auth/AuthUserPagePresenter';
import { Provider } from 'react-redux';
import { configureStore } from './r/configureStore';
import { createBrowserRouter, Routes, Route, RouterProvider } from 'react-router-dom';
import { ReactionLibraryPage } from './c/reactionLibraryPage/ReactionLibraryPage';
import { WipPage } from './c/shell/WipPage';
import { PatreonPostsPage } from './c/patreonPostsPage/PatreonPostsPage';

function DefinedRoutes() {
    return (
        <Routes>
            <Route element={<RootShell />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/movies" element={<AuthUserPagePresenter><MovieLibraryPage /></AuthUserPagePresenter>} />
                <Route path="/movies/:contentMovieId" element={<AuthUserPagePresenter><MovieDetailsPage /></AuthUserPagePresenter>} />
                <Route path="/shows" element={<AuthUserPagePresenter><WipPage message={'TV shows coming soon!'} /></AuthUserPagePresenter>} />
                <Route path="/reactors" element={<AuthUserPagePresenter><ReactorsPage /></AuthUserPagePresenter>} />
                <Route path="/reactors/:reactorRnryUserId" element={<AuthUserPagePresenter><ReactorDetailsPage /></AuthUserPagePresenter>} />
                <Route path="/reactions" element={<AuthUserPagePresenter><ReactionLibraryPage /></AuthUserPagePresenter>} />
                <Route path="/patreonposts" element={<AuthUserPagePresenter><PatreonPostsPage /></AuthUserPagePresenter>} />
                <Route path="/user" element={<AuthUserPagePresenter><UserPage /></AuthUserPagePresenter>} />
                <Route path="/help" element={<HelpPage />} />
            </Route>
        </Routes>
    );
};

const router = createBrowserRouter([
    { path: '*', Component: DefinedRoutes }

]);

const configuredStore = configureStore();

export const App = () => {
    return (
        <Provider store={configuredStore}>
            <RouterProvider router={router} />
        </Provider>
    );
};