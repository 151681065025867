import { createSelector } from 'reselect';
import * as recordStore from '../recordStore';
import * as constants from '../constants';
//import * as recordStore from '../../r/recordStore';

const selectMemberships = state => state.patreonStore.memberships;
const selectPosts = state => state.patreonStore.posts;
const selectIsLoadingPatreonPosts = state => state.patreonStore.isLoadingPatreonPosts;

const selectReactionLibraryMetadataMediaForPostId = createSelector(
    [
        (state, _patreonPostId) => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia),
        (_state, patreonPostId) => patreonPostId
    ],
    (mediaRecords, patreonPostId) => mediaRecords.find(x => x.patreonPostId === patreonPostId)
);

const selectPatreonPostsWithLinkedReactions = createSelector(
    [
        selectPosts,
        state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia)
    ],
    (posts, mediaRecords) => posts ? posts.map(x => { return { ...x, libraryRecord: mediaRecords.find(lib => lib.patreonPostId === x.id) }; }) : []
);

//const selectReactionLibraryMetadataMediaForPostId = createSelector(
//    [
//        selectReactionLibraryMetadataMediasForPostId,
//        (_state, _patreonPostId, reactionType) => reactionType
//    ],
//    (mediaRecords, patreonPostId, reactionType) => mediaRecords.find(x => x.rnryReactionType === reactionType)
//);

export const selectors = {
    selectMemberships,
    selectPosts,
    selectPatreonPostsWithLinkedReactions,
    selectReactionLibraryMetadataMediaForPostId,
    selectIsLoadingPatreonPosts
}