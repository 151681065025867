import React, { useCallback, useEffect } from 'react';
import * as constants from '../../r/constants';
import * as tmdbStore from '../../r/tmdbStore';
import * as recordStore from '../../r/recordStore';
import * as authStore from '../../r/authStore';
import * as rnryStore from '../../r/rnryStore';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { ReactionMediaMiniDetailsWatcher } from './ReactionMediaMiniDetailsWatcher';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarBorder';
import Tooltip from '@mui/material/Tooltip';

const classes = {
    posterBoxList: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        backgroundColor: 'grey.800',
        justifyContent: 'start',
        overflowY: 'auto'
    },
    posterBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        height: '410px',
        margin: 2,
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 0,
        //overflowY: 'auto'
    },
    breadcrumbs: {
        m: 3
    },
    link: {
        textDecoration: 'none'
    },
    cardMedia: {
        height: 300,
        width: 200
    },
    movieInfoPanel: {
        display: 'flex',
        m: 6,
        maxWidth: '1500px'
    },
    movieInfoText: {
        display: 'flex',
        flexDirection: 'column',
        ml: 4
    },
    reactorList: {
        pl: 4,
        pr: 4,
        //mb: 4,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexGrow: 1,
        alignItems: 'flex-start',
        overflowY: 'auto'
    },
    reactorAvatar: {
        ml: 2,
        mr: 2,
        mt: 2,
        mb: 2,
        width: '180px',
        height: '180px'
    },
    reactorListHeader: {
        typography: 'h4',
        m: 0,
        textTransform: 'uppercase'
    },
    progressContainer: {
        display: 'flex',
        flexGrow: 1,
        mt: 10,
        justifyContent: 'center'
    },
    movieYearRuntimeLine: {
        display: 'flex'
    },
    posterBoxTitle: {
        marginTop: 1,
        fontSize: 'body2.fontSize',
        color: 'common.white',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    posterBoxBorder: {
        border: 2,
        borderColor: 'grey.800',
        textDecoration: 'none',
        borderRadius: '10px',
        margin: '12px',
        backgroundColor: 'grey.900',
        boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none'
    },
    poster: {
        position: 'relative'
    },
    bookmarkIcon: {
        height: '32px',
        width: '32px'
    },
    bookmarkIconBox: {
        width: '40px',
        height: '40px',
        backgroundColor: '#831c1c',
        color: 'white',
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cornerRadius: '0 10px 0 10px'
    }
}

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const MovieDetailsPage = () => {

    const dispatch = useDispatch();

    const { contentMovieId } = useParams();

    const metadataMovie = useSelector(state => rnryStore.selectors.selectMetadataMoviesWithMediaForMetadataMasterId(state, contentMovieId));
    const userLibMovie = useSelector(state => rnryStore.selectors.selectLibraryExpandedMovieByMetadataMasterId(state, constants.rnryLibraryTypes.user, contentMovieId));
    //const reactionLibraryRecords = useSelector(state => rnryStore.selectors.selectReactorReactionMoviesWithMediaForRnryMetadataMasterId(state, contentMovieId));
    const reactionLibraryRecords = useSelector(state => rnryStore.selectors.selectReactionLibraryMoviesWithMediaAndWatchStatusForMetadataMasterIdAndTnsUserId(state, contentMovieId));
    const tmdbConfiguration = useSelector(state => tmdbStore.selectors.selectTmdbConfiguration(state));
    const addMetadataToLibrary = useCallback(record => dispatch(recordStore.actionCreators.createRecord(constants.rnryRecordTypes.rnryUserLibraryMetadataMaster, record)), [dispatch]);
    const removeLibraryMovie = useCallback(record => dispatch(recordStore.actionCreators.deleteRecord(constants.rnryRecordTypes.rnryUserLibraryMetadataMaster, record)), [dispatch]);
    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const tnsUserId = useSelector(state => authStore.selectors.selectTnsUserId(state));
    const userLibraryMovies = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryUserLibraryMetadataMaster));
    const loadReactions = useCallback(() => dispatch(rnryStore.actionCreators.getMovieReactions(contentMovieId)), [dispatch, contentMovieId]);

    let ignore = false;

    useEffect(() => {
        if (!ignore) {
            loadReactions();
        }
        return () => {
            ignore = true;
        }
    }, []);

    const getStandardEditionRuntime = useCallback(() => {
        if (metadataMovie && metadataMovie.editions) {
            const edition = metadataMovie.editions.find(x => x.edition === constants.rnryEditionTypes.standard);
            if (edition && edition.runtimeSeconds) {
                return `${(edition.runtimeSeconds / 60)} mins`;
                //var calcTime = new Date(null);
                //calcTime.setSeconds(edition.runtimeSeconds);
                //var newTime = calcTime.toISOString().substr(11, 8);
                //return newTime;
                //return edition.runtimeSeconds;
            } else {
                return '';
            }
        }
    }, [metadataMovie]);

    const isLoaded = useCallback(() => {
        return metadataMovie && tmdbConfiguration && tmdbConfiguration.images;
    }, [metadataMovie, tmdbConfiguration]);

    const getImageUrl = () => {
        if (metadataMovie.tmdbPosterPath) {
            return `${tmdbConfiguration.images.secure_base_url}${tmdbConfiguration.images.poster_sizes[4]}${metadataMovie.tmdbPosterPath}`;
        } else {
            return '/i/no-image.jpg';
        }
    }

    const removeFromLibrary = useCallback(metadata => {
        if (isLoggedIn) {
            const libraryMovie = metadata && userLibraryMovies.find(lib => lib.rnryMetadataMasterId === metadata.id);
            if (libraryMovie) {
                removeLibraryMovie(libraryMovie);
            }
        }
    }, [isLoggedIn, userLibraryMovies, removeLibraryMovie]);

    const addToLibrary = useCallback(metadata => {
        if (isLoggedIn) {
            const userLib = {
                rnryMetadataMasterType: metadata.rnryMetadataType,
                rnryMetadataMasterId: metadata.id,
                tnsUserId: tnsUserId
            }
            addMetadataToLibrary(userLib);
        }
    }, [addMetadataToLibrary, isLoggedIn, tnsUserId]);

    const getLibraryIcon = metadataMovie => {
        const isIn = metadataMovie && userLibraryMovies.some(lib => lib.rnryMetadataMasterId === metadataMovie.id);
        const tooltip = isIn ? 'Remove from library' : 'Add to library';
        const starIcon = isIn ? <StarIcon sx={classes.bookmarkIcon} /> : <StarOutlineIcon sx={{ ...classes.bookmarkIcon, color: 'rgb(255,255,255,0.5)' }} />;
        const icon = (
            <DelayedTooltip title={tooltip}>
                <Box sx={classes.bookmarkIconBox}>
                    <IconButton disableRipple onClick={isIn ? () => removeFromLibrary(metadataMovie) : () => addToLibrary(metadataMovie)}>
                        {starIcon}
                    </IconButton>
                </Box>
            </DelayedTooltip>
        );
        return icon;
    };

    const getContent = () => {
        return (
            <React.Fragment>
                <Breadcrumbs sx={classes.breadcrumbs} >
                    <Link component={RouterLink} to={'/movies'} sx={classes.link}>{'Movie Library'}</Link>
                    <Typography>{metadataMovie.displayTitle}</Typography>
                </Breadcrumbs>
                <Box sx={classes.posterBoxList}>
                    <Box sx={classes.movieInfoPanel}>
                        <Box sx={classes.poster}>
                            <img height={300} width={200} src={getImageUrl()} alt={metadataMovie.displayTitle} />
                            {getLibraryIcon(metadataMovie)}
                        </Box>
                        <Box sx={classes.movieInfoText}>
                            <Typography sx={{ typography: 'h4' }}>{metadataMovie.displayTitle}</Typography>
                            <Box sx={classes.movieYearRuntimeLine}>
                                <Typography sx={{ color: 'grey.400' }}>{metadataMovie.releaseDate.substring(0, 4)}</Typography>
                                <Typography sx={{ ml: 3, color: 'grey.400' }}>{getStandardEditionRuntime()}</Typography>
                            </Box>
                            <Typography sx={{ typography: 'h6', mt: 2, fontStyle: 'italic' }}>{metadataMovie.tagline}</Typography>
                            <Typography sx={{ mt: 2 }}>{metadataMovie.overview}</Typography>
                        </Box>
                    </Box>
                    <Divider flexItem variant={'middle'}>
                        <Typography sx={classes.reactorListHeader}>{'Reactions'}</Typography>
                    </Divider>
                    <Box sx={classes.reactorList}>
                        {reactionLibraryRecords.filter(x => x.rnryReactionMedias && x.rnryReactionMedias.length > 0).sort((a, b) => a.reactorRecord.reactorNamePatreon > b.reactorRecord.reactorNamePatreon ? 1 : -1).map(lib => {
                            if (lib.reactorRecord) {
                                return (
                                    <Box sx={classes.posterBoxBorder} key={lib.rnryUserId}>
                                        <Box sx={classes.posterBox}>
                                            <Box component={RouterLink} to={`/reactors/${lib.rnryUserId}`} >
                                                <Avatar sx={classes.reactorAvatar} alt={lib.reactorRecord.reactorNamePatreon} src={lib.reactorRecord.avatarUrlPatreon} />
                                            </Box>
                                            <Typography sx={classes.posterBoxTitle}>{lib.reactorRecord.reactorNamePatreon}</Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <ReactionMediaMiniDetailsWatcher userLibraryMovieId={userLibMovie?.id} libraryExpandedRecord={lib} rnryReactionType={constants.rnryReactionTypes.fullLength} />
                                            <ReactionMediaMiniDetailsWatcher userLibraryMovieId={userLibMovie?.id} libraryExpandedRecord={lib} rnryReactionType={constants.rnryReactionTypes.edited} />
                                        </Box>
                                    </Box>
                                )
                            } else {
                                return [];
                            }
                        })}
                    </Box>
                </Box>
            </React.Fragment>
        );
    };

    return (
        <Box sx={classes.pageContainer}>
            {!isLoaded() &&
                <Box sx={classes.progressContainer}>
                    <CircularProgress />
                </Box>
            }
            {isLoaded() && getContent()}
        </Box>
    );
};