import * as actionTypes from './actionTypes';

const initialState = {
    movies: [],
    searchMovieResults: [],
    tmdbConfiguration: {}
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case actionTypes.receiveMovies:
            return {
                ...state,
                movies: [{ movieTitle: 'Goldfinger', movieYear: 1964 }]
            };

        case actionTypes.receiveSearchMovieResults:
            return {
                ...state,
                searchMovieResults: action.searchResults
            };

        case actionTypes.receiveTmdbConfiguration:
            return {
                ...state,
                tmdbConfiguration: action.tmdbConfiguration
            };

        default:
            return state;
    }
};
