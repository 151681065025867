import React, { useState, useEffect, useCallback } from 'react';
import * as constants from '../../r/constants';
import * as rnryStore from '../../r/rnryStore';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { ReactionLibraryLinkToPostItem } from './ReactionLibraryLinkToPostItem';
import { SearchBoxHeader } from '../helpers/SearchBoxHeader';

const classes = {
    dialog: {
        height: '74%'
    },
    dialogTitle: {
        backgroundColor: '#121212'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        justifyContent: 'center'
    },
    movieList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowX: 'hidden',
        justifyContent: 'center'
    }
};

export const ReactionLibraryDialog = ({ isOpen, setIsOpen, currentRequestedPatreonPostToLink }) => {

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    //const libraryRecordsExpandedAll = useSelector(state => rnryStore.selectors.selectLibraryExpandedMovies(state, constants.rnryLibraryTypes.reaction));
    const libraryRecordsExpandedAll = useSelector(state => rnryStore.selectors.selectLibraryExpandedMoviesForRnryUserId(state, constants.rnryLibraryTypes.reaction, rnryUserIdToManage));
    
    const minSearchTextLength = 1;
    const [searchText, setSearchText] = useState('');
    const [libraryRecordsExpanded, setLibraryRecordsExpanded] = useState('');

    const applyFilter = useCallback(() => {
        if (libraryRecordsExpandedAll.length > 0 && searchText.trim().length >= minSearchTextLength) {
            // TODO: Include metadata in objects and search on linked movie title in addition to post title
            setLibraryRecordsExpanded(libraryRecordsExpandedAll.filter(x => x.metadata.displayTitle.toLowerCase().includes(searchText.trim().toLowerCase())));
        } else {
            setLibraryRecordsExpanded([...libraryRecordsExpandedAll]);
        }
    }, [libraryRecordsExpandedAll, searchText]);

    useEffect(() => {
        applyFilter();
    }, [searchText, applyFilter]);

    useEffect(() => {
        if (libraryRecordsExpandedAll) {
            setLibraryRecordsExpanded([...libraryRecordsExpandedAll]);
            applyFilter();
        }
    }, [applyFilter, libraryRecordsExpandedAll]);

    return (
        <Dialog PaperProps={{ sx: classes.dialog }} open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth={'lg'}>
            <DialogTitle sx={classes.dialogTitle}>{'Link Reaction to Patreon Post'}</DialogTitle>
            <Box sx={classes.container}>
                <SearchBoxHeader searchText={searchText} setSearchText={setSearchText} minLength={minSearchTextLength} />
                <Box sx={classes.movieList}>
                    {libraryRecordsExpanded && libraryRecordsExpanded.length > 0 && libraryRecordsExpanded.sort((a, b) => a.metadata.displayTitle > b.metadata.displayTitle ? 1 : -1).map(lib => <ReactionLibraryLinkToPostItem key={lib.id} libraryExpandedItem={lib} currentRequestedPatreonPostToLink={currentRequestedPatreonPostToLink} setIsOpen={setIsOpen} />)}
                </Box>
            </Box>
        </Dialog>
    );
};