import * as actionTypes from './actionTypes';

const initialState = {
    memberships: [],
    posts: [],
    isLoadingPatreonPosts: false
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case actionTypes.receiveMembershipCreatorRecords:
            return {
                ...state,
                memberships: action.memberships
            };

        case actionTypes.receivePatreonPosts:
            return {
                ...state,
                posts: action.posts
            };

        case actionTypes.setIsLoadingPatreonPosts:
            return {
                ...state,
                isLoadingPatreonPosts: action.isLoading
            };

        default:
            return state;
    }
};
