import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import FreeIcon from '@mui/icons-material/MoneyOff';
import * as constants from '../../r/constants';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import FirstTimeWatchedIcon from '@mui/icons-material/RepeatOne';
import RepeatWatchIcon from '@mui/icons-material/Repeat';

const classes = {
    mediaIcons: {
        mt: 0,
        display: 'flex'
    }
};

const DelayedTooltip = props => <Tooltip {...props} enterDelay={500} />

export const ReactionMediaDetailsEditorIconStrip = ({ isFree, setIsFree, isBlurred, setIsBlurred, isFirstTimeWatch, setIsFirstTimeWatch, iconSize }) => {

    const getIsFreeIcon = () => {
        let tooltip = 'Reaction requires paid access';
        let icon = <MoneyIcon fontSize={iconSize} sx={{}} />;
        if (isFree) {
            tooltip = 'Reaction is free to access';
            icon = <FreeIcon fontSize={iconSize} sx={{ color: 'gray' }} />;
        }
        return (
            <Box sx={{ m: 1 }}>
                <DelayedTooltip title={tooltip}>
                    <IconButton onClick={() => setIsFree(!isFree)}>
                        {icon}
                    </IconButton>
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsBlurredIcon = () => {
        let tooltip = 'Media content is not blurred out';
        let icon = <BlurOffIcon fontSize={iconSize} sx={{ color: 'gray' }} />;
        if (isBlurred) {
            tooltip = 'Media content is blurred out';
            icon = <BlurOnIcon fontSize={iconSize} sx={{}} />;
        }
        return (
            <Box sx={{ m: 1 }}>
                <DelayedTooltip title={tooltip}>
                    <IconButton onClick={() => setIsBlurred(!isBlurred)}>
                        {icon}
                    </IconButton>
                </DelayedTooltip>
            </Box>
        );
    };

    const getIsFirstTimeWatchedIcon = () => {
        let tooltip = 'Reaction is a repeat watch';
        let icon = <RepeatWatchIcon fontSize={iconSize} sx={{ color: 'gray' }} />;
        if (isFirstTimeWatch) {
            tooltip = 'Reaction is a first time watch';
            icon = <FirstTimeWatchedIcon fontSize={iconSize} sx={{}} />;
        }
        return (
            <Box sx={{ m: 1 }}>
                <DelayedTooltip title={tooltip}>
                    <IconButton onClick={() => setIsFirstTimeWatch(!isFirstTimeWatch)}>
                        {icon}
                    </IconButton>
                </DelayedTooltip>
            </Box>
        );
    };

    return (
        <Box sx={classes.mediaIcons}>
            {getIsFirstTimeWatchedIcon()}
            {getIsBlurredIcon()}
            {getIsFreeIcon()}
        </Box>
    );
};