import { put, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as recordStore from '../recordStore';
import * as authStore from '../authStore';
import axios from 'axios';
import * as constants from '../constants';

export const sagaWatchers = (sagaMiddleware) => {
    sagaMiddleware.run(watchAddItemToLibrarySaga);
    sagaMiddleware.run(watchGetReactorReactionsSaga);
    sagaMiddleware.run(watchGetMovieReactionsSaga);
};

function* watchAddItemToLibrarySaga() {
    yield takeEvery(actionTypes.addItemToLibrarySaga, addItemToLibrary);
}

function* addItemToLibrary({ rnryLibraryType, rnryMetadataType, tmdb, rnryUserId }) {
    let libraryTypeSlug = '';
    let libraryRecordType = '';
    let metadataMasterRecordType = '';
    let metadataEditionRecordType = '';
    let contentRecordTypeSlug = '';
    switch (rnryLibraryType) {
        case constants.rnryLibraryTypes.user:
            libraryTypeSlug = 'library-items';
            libraryRecordType = constants.rnryRecordTypes.rnryUserLibraryMetadataMaster;
            break;

        case constants.rnryLibraryTypes.reaction:
            libraryTypeSlug = 'reactions';
            libraryRecordType = constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster;
            break;

        default:
            throw new Error('Invalid rnryLibraryType');
    }
    switch (rnryMetadataType) {
        case constants.rnryMetadataTypes.movie:
            metadataMasterRecordType = constants.rnryRecordTypes.rnryMetadataMovie;
            metadataEditionRecordType = constants.rnryRecordTypes.rnryMetadataMovieEdition;
            contentRecordTypeSlug = 'movies';
            break;

        case constants.rnryMetadataTypes.show:
            // TODO: Will need to make more changes for shows
            metadataMasterRecordType = constants.rnryRecordTypes.rnryContentShow;
            contentRecordTypeSlug = 'shows';
            break;

        default:
            throw new Error('Invalid rnryMetadataType');
    }
    const tmdbId = tmdb.id.toString().toLowerCase().trim();
    if (!tmdbId || tmdbId === '') {
        throw new Error('Invalid tmdbId');
    }
    const userId = yield select(authStore.selectors.selectTnsUserId);
    let url = `/api/u/${userId}/${libraryTypeSlug}/tmdb/${contentRecordTypeSlug}/${tmdbId}`;
    if (rnryUserId && rnryUserId !== '') {
        url = `/api/ru/${rnryUserId}/${libraryTypeSlug}/tmdb/${contentRecordTypeSlug}/${tmdbId}`;
    }
    const result = yield axios.post(url).catch(error => error);
    if (result.isAxiosError) {
        // TODO: Add error handling
    } else {
        const metadataMasterRecord = result.data.records.find(x => x.recordType === metadataMasterRecordType);
        if (metadataMasterRecord) {
            yield put(recordStore.actionCreators.updateRecordSuccess(metadataMasterRecordType, metadataMasterRecord));
        }
        const metadataEditionRecord = result.data.records.find(x => x.recordType === metadataEditionRecordType);
        if (metadataEditionRecord) {
            yield put(recordStore.actionCreators.updateRecordSuccess(metadataEditionRecordType, metadataEditionRecord));
        }
        const libraryRecord = result.data.records.find(x => x.recordType === libraryRecordType);
        if (libraryRecord) {
            yield put(recordStore.actionCreators.updateRecordSuccess(libraryRecordType, libraryRecord));
        }
    }
}

function* watchGetReactorReactionsSaga() {
    yield takeEvery(actionTypes.getReactorReactionsSaga, getReactorReactions);
}

function* getReactorReactions({ rnryUserId }) {
    rnryUserId = rnryUserId.toString().toLowerCase().trim();
    if (!rnryUserId || rnryUserId === '') {
        throw new Error('Invalid rnryUserId');
    }
    const baseUrl = `/api/ru/${rnryUserId}/`;
    yield getReactionRecordsInternal(baseUrl, x => x.rnryUserId !== rnryUserId, x => x.rnryUserId !== rnryUserId);
}

function* watchGetMovieReactionsSaga() {
    yield takeEvery(actionTypes.getMovieReactionsSaga, getMovieReactions);
}

function* getMovieReactions({ rnryMetadataMasterId }) {
    rnryMetadataMasterId = rnryMetadataMasterId.toString().toLowerCase().trim();
    if (!rnryMetadataMasterId || rnryMetadataMasterId === '') {
        throw new Error('Invalid rnryMetadataMasterId');
    }
    const baseUrl = `/api/movies/${rnryMetadataMasterId}/`;
    yield getReactionRecordsInternal(baseUrl, x => x.rnryMetadataMasterId !== rnryMetadataMasterId);
}

function* getReactionRecordsInternal(baseUrl, filterClause) {
    const masterUrl = `${baseUrl}reactions`;
    const result = yield axios.get(masterUrl).catch(error => error);
    if (result.isAxiosError) {
        // TODO: Add error handling
    } else {
        const mediaUrl = `${baseUrl}reaction-medias`;
        const resultMedia = yield axios.get(mediaUrl).catch(error => error);
        if (resultMedia.isAxiosError) {
            // TODO: Add error handling
        } else {
            const reactions = result.data;
            const reactionMedias = resultMedia.data;

            const masterRecords = yield select(recordStore.selectors.selectRecords, constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster);
            const mediaRecords = yield select(recordStore.selectors.selectRecords, constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia);

            const newMasterRecords = [...masterRecords.filter(filterClause), ...reactions];
            const newMediaRecords = [...mediaRecords.filter(filterClause), ...reactionMedias];

            yield put(recordStore.actionCreators.receiveRecords(constants.rnryRecordTypes.rnryReactionLibraryMetadataMaster, newMasterRecords));
            yield put(recordStore.actionCreators.receiveRecords(constants.rnryRecordTypes.rnryReactionLibraryMetadataMedia, newMediaRecords));
        }
    }
}