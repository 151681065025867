import * as actionTypes from './actionTypes';

export const actionCreators = {
    getMovies: () => dispatch => {
        dispatch({ type: actionTypes.getMoviesSaga });
    },

    receiveMovies: () => dispatch => {
        dispatch({ type: actionTypes.receiveMovies });
    },

    searchMovies: searchText => dispatch => {
        dispatch({ type: actionTypes.searchMoviesSaga, searchText });
    },

    receiveSearchMovieResults: searchResults => dispatch => {
        dispatch({ type: actionTypes.receiveSearchMovieResults, searchResults });
    },

    getTmdbConfiguration: () => dispatch => {
        dispatch({ type: actionTypes.getTmdbConfigurationSaga });
    },

    receiveTmdbConfiguration: tmdbConfiguration => dispatch => {
        dispatch({ type: actionTypes.receiveTmdbConfiguration, tmdbConfiguration });
    }
};
