import React, { useCallback, useEffect } from 'react';
import * as rnryStore from '../../r/rnryStore';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import * as recordStore from '../../r/recordStore';
import * as constants from '../../r/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactorLibraryMediaContainer } from './ReactorLibraryMediaContainer';

const classes = {
    pageContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'grey.800',
        justifyContent: 'start',
        overflowY: 'auto'
    },
    reactorDetails: {
        display: 'flex',
        flexDirection: 'row',
        margin: 2,
        alignItems: 'center'
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 0
    },
    breadcrumbs: {
        m: 3
    },
    link: {
        textDecoration: 'none'
    },
    cardMedia: {
        height: 300,
        width: 200
    },
    movieInfoPanel: {
        display: 'flex',
        m: 6,
        maxWidth: '1500px'
    },
    movieInfoText: {
        display: 'flex',
        flexDirection: 'column',
        ml: 4
    },
    reactorList: {
        pl: 4,
        pr: 4,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexGrow: 1,
        alignItems: 'flex-start',
        overflowY: 'auto'
    },
    reactorAvatar: {
        ml: 2,
        mr: 2,
        mt: 2,
        mb: 2,
        width: '100px',
        height: '100px'
    },
    listHeaderText: {
        typography: 'h4',
        m: 0,
        textTransform: 'uppercase'
    },
    progressContainer: {
        display: 'flex',
        flexGrow: 1,
        mt: 10,
        justifyContent: 'center'
    },
    reactorName: {
        marginLeft: 2,
        fontSize: 'h4.fontSize',
        color: 'common.white',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    posterBoxBorder: {
        border: 2,
        borderColor: 'grey.800',
        textDecoration: 'none',
        borderRadius: '10px',
        margin: '12px',
        backgroundColor: 'grey.900',
        boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none'
    }
}

export const ReactorDetailsPage = () => {

    const { reactorRnryUserId } = useParams();

    const dispatch = useDispatch();

    const reactors = useSelector(state => rnryStore.selectors.selectReactorRecords(state));
    const reactorReactions = useSelector(state => rnryStore.selectors.selectReactorReactionMoviesWithMediaAndWatchStatusForTnsUserId(state, reactorRnryUserId));
    const loadReactions = useCallback(() => dispatch(rnryStore.actionCreators.getReactorReactions(reactorRnryUserId)), [dispatch, reactorRnryUserId]);

    const currentReactor = reactors.find(x => x.rnryUserRecord && x.rnryUserRecord.id === reactorRnryUserId);

    const isLoaded = useCallback(() => {
        return currentReactor && true;
    }, [currentReactor]);

    let ignore = false;

    useEffect(() => {
        if (!ignore) {
            loadReactions();
        }
        return () => {
            ignore = true;
        }
    }, []);
    
    // TODO: Figure out why this rnryReactionMedias filter below doesn't update when a reaction is added/removed vs why the filter on MovieDetailsPage does update.
    // Maybe because this one uses the custom saga to read the API data? Or the inline null checks?

    const getContent = () => {
        return (
            <React.Fragment>
                <Breadcrumbs sx={classes.breadcrumbs} >
                    <Link component={RouterLink} to={'/reactors'} sx={classes.link}>{'Reactors'}</Link>
                    <Typography>{currentReactor.reactorNamePatreon}</Typography>
                </Breadcrumbs>
                <Box sx={classes.pageContent}>
                    <Box sx={classes.reactorDetails}>
                        <Avatar sx={classes.reactorAvatar} alt={currentReactor.reactorNamePatreon} src={currentReactor.avatarUrlPatreon} />
                        <Typography sx={classes.reactorName}>{currentReactor.reactorNamePatreon}</Typography>
                    </Box>
                    <Divider flexItem variant={'middle'}>
                        <Typography sx={classes.listHeaderText}>{'Movie Reactions'}</Typography>
                    </Divider>
                    <Box sx={classes.reactorList}>
                        {reactorReactions && reactorReactions.length > 0 && reactorReactions.sort((a, b) => a.metadata.displayTitle > b.metadata.displayTitle ? 1 : -1).filter(x => x.rnryReactionMedias && x.rnryReactionMedias.length > 0).map(record => <ReactorLibraryMediaContainer key={record.id} libraryRecord={record} />)}
                    </Box>
                </Box>
            </React.Fragment>
        );
    };

    return (
        <Box sx={classes.pageContainer}>
            {!isLoaded() &&
                <Box sx={classes.progressContainer}>
                    <CircularProgress />
                </Box>
            }
            {isLoaded() && getContent()}
        </Box>
    );
};