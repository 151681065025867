import React, { useCallback, useState } from 'react';
import * as tmdbStore from '../../r/tmdbStore';
import * as rnryStore from '../../r/rnryStore';
import * as patreonStore from '../../r/patreonStore';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as recordStore from '../../r/recordStore';
import * as constants from '../../r/constants';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { ReactorSearchDialog } from './ReactorSearchDialog';

const classes = {
    posterBoxList: {
        flexGrow: 1,
        p: 3,
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        backgroundColor: 'grey.800',
        overflowY: 'auto'
    },
    posterBoxBorder: {
        border: 2,
        borderColor: 'grey.800',
        textDecoration: 'none',
        borderRadius: '10px',
        margin: '12px',
        backgroundColor: 'grey.900',
        boxShadow: '3px 3px 5px -1px rgba(0,0,0,0.4), 5px 5px 8px 0px rgba(0,0,0,0.28), 1px 1px 14px 0px rgba(0,0,0,0.24)',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        webkitUserSelect: 'none',
        userSelect: 'none',
        transform: 'translate(0%, 0%)',
        transition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        webkitTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        mozTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        oTransition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        '&:hover': {
            cursor: 'pointer',
            transform: 'translate(-2%, -2%)',
            transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
        },
        '&:active': {
            cursor: 'pointer',
            transform: 'translate(-1%, -1%)',
            transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            webkitTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            mozTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            oTransition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
        }
    },
    posterBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        height: '250px',
        margin: 2,

    },
    posterBoxTitle: {
        marginTop: 2,
        fontSize: 'body2.fontSize',
        color: 'common.white',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    posterBoxYear: {
        marginTop: 0,
        fontSize: 'body2.fontSize',
        color: 'grey.500',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden'
    },
    reactorAvatar: {
        ml: 2,
        mr: 2,
        mt: 2,
        mb: 2,
        width: '180px',
        height: '180px'
    },
    loadingBox: {
        flexGrow: 1,
        p: 3,
        display: 'flex',
        //flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: 'grey.800'
    },
    refreshButton: {
        margin: '-8px'
    },
    menuBarSpacer: {
        flexGrow: 1
    },
    addButton: {
        ml: 2,
        height: '24px',
        width: '24px',
        minHeight: '24px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 0
    },
    menuBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 0,
        m: 3
    }
}

export const ReactorsPage = () => {

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    //const memberships = useSelector(state => patreonStore.selectors.selectMemberships(state));
    const userReactors = useSelector(state => recordStore.selectors.selectRecords(state, constants.rnryRecordTypes.rnryUserReactor));
    const reactors = useSelector(state => rnryStore.selectors.selectReactorRecords(state));
    const isLoadedReactors = useSelector(state => recordStore.selectors.selectIsLoaded(state, constants.rnryRecordTypes.rnryReactorExpandedDto));
    const isLoadedUserReactors = useSelector(state => recordStore.selectors.selectIsLoaded(state, constants.rnryRecordTypes.rnryUserReactor));
    const refreshMemberships = useCallback(() => dispatch(patreonStore.actionCreators.refreshPatreonMemberships()), [dispatch]);

    return (
        <Box sx={classes.container}>
            <ReactorSearchDialog isOpen={isOpen} setIsOpen={setIsOpen} />
            <Box sx={classes.menuBar}>
                <Typography>{'Reactors'}</Typography>
                <Tooltip title={'Add Reactor'} arrow placement={'right'}>
                    <Fab sx={classes.addButton} size={'small'} onClick={() => setIsOpen(true)} color={'primary'}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <Box sx={classes.menuBarSpacer} />
                <IconButton sx={classes.refreshButton} onClick={refreshMemberships} >
                    <RefreshIcon />
                </IconButton>
            </Box>
            {(!isLoadedReactors || !isLoadedUserReactors) &&
                <Box sx={classes.loadingBox}>
                    <CircularProgress />
                </Box>
            }
            {isLoadedReactors && isLoadedUserReactors &&
                <Box sx={classes.posterBoxList}>
                    {userReactors.map(r => {
                        var reactor = reactors.find(x => x.rnryUserRecord.id === r.reactorRnryUserId);
                        if (!reactor) return []
                        return (
                            <Box component={Link} sx={classes.posterBoxBorder} key={r.reactorRnryUserId} to={`/reactors/${r.reactorRnryUserId}`} >
                                <Box sx={classes.posterBox}>
                                    <Avatar sx={classes.reactorAvatar} alt={reactor.reactorNamePatreon} src={reactor.avatarUrlPatreon} />
                                    <Typography sx={classes.posterBoxTitle}>{reactor.reactorNamePatreon}</Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            }
        </Box>
    );
};