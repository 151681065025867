import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const classes = {
    outerContainer: {
        flexGrow: '1',
        overflowY: 'auto',

    },
    container: {
        my: 4,
        backgroundColor: 'grey.900',
        display: 'flex',
        flexDirection: 'column'
    },
    imageBox: {
        my: 4,
        display: 'flex',
        justifyContent: 'center'
    },
    sectionTitle: {
        mt: 4,
        mb: 2
    }
}

export const HelpPage = () => {
    return (
        <Box sx={classes.outerContainer}>
            <Container sx={classes.container}>
                <Box sx={{ alignSelf: 'center', mx: 4, mt: 4, mb: 0, display: 'flex', borderRadius: '3px', p: '1px', boxShadow: '2px 2px 2px 1px #121212', border: '1px #373737 solid' }} >
                    <img width={500} src={'/i/logo-reactionairy.png'} alt={'Reactionairy Logo'} />
                </Box>
                <Typography variant={'h3'} sx={classes.sectionTitle}>
                    {'Movies'}
                </Typography>
                <Typography variant={'h6'}>
                    {`Discover new reactions and track reactions you've seen by adding movies to your movie library. Click the blue plus button at the top of the page to search for movies to add to your library.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-movies.png'} alt={'Movie Library Help'} />
                </Box>
                <Typography variant={'h6'}>
                    {`Once you've found a movie you want to include in your library, click the star in the top right corner of the movie poster. A solid star represents a movie that is already in your library. If you click a solid star, you can remove the movie from your library.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-addmovies.png'} alt={'Add Movies Help'} />
                </Box>
                <Typography variant={'h6'}>
                    {`If you click on a movie in your library from the movie library page, you will see details and reactions for that movie. There may be a full-length and/or an edited reaction listed for each reactor. The icons provide additional details of the reaction (hover over the icon for a description). If the reaction has been mapped to a Patreon post, the link icon in the top right of the reaction details box will take you directly to the reaction. PLEASE NOTE: Reactions from many more reactors will be added soon!`}
                </Typography>
                <Typography sx={{ mt: 2 }} variant={'h6'}>
                    {`If the reactor is in your reactor library (see Reactors section below), you will see the checkmark icon to the left of the reaction details box. You can toggle this on and off to mark if you have watched either version of the reaction for that movie from that particular reactor. For the checkmark to appear, you must have both the movie in your movie library and the reactor in your reactor library.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-moviedetails.png'} alt={'Movie Details Help'} />
                </Box>
                <Typography variant={'h3'} sx={classes.sectionTitle}>
                    {'TV Shows'}
                </Typography>
                <Typography variant={'h6'}>
                    {`TV show tracking is coming soon!`}
                </Typography>
                <Typography variant={'h3'} sx={classes.sectionTitle}>
                    {'Reactors'}
                </Typography>
                <Typography variant={'h6'}>
                    {`Discover new reactors to follow by adding them to your reactor library. Click the blue plus button at the top of the page to search for reactors to add to your library. When you first sign in, any reactors that you subscribe to on Patreon will be automatically added. You can click the refresh button in the top right at any time to update any new reactors you follow on Patreon. Note, there is no distinction in Patreon for reactors, so any Patreon creator you subscribe to will be listed here initially. If you want, you can remove them by deselecting their star in the add reactors page when you click the blue plus icon.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-reactors.png'} alt={'Reactor Library Help'} />
                </Box>
                <Typography variant={'h6'}>
                    {`If you click on a reactor in your library from the reactor library page, you will see details and reactions for that reactor. The details for the reactions work the same as described above. You can easily toggle back and forth between movies per reactor or reactors per movie by clicking on the movie posters and reactor images.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-reactordetails.png'} alt={'Reactor Details Help'} />
                </Box>
                <Typography variant={'h3'} sx={classes.sectionTitle}>
                    {'Manage Reactions (Reactors Only)'}
                </Typography>
                <Typography variant={'h6'}>
                    {`If you are a reactor (i.e. you are a Patreon creator with a campaign), you can manage your reactions here. This page works very similar to the library pages above, but note that your reaction library as a reactor is distinct and different than your movie library above that tracks reactions you've watched. You can add both a full-length and edited version for each movie and provide details such as whether the movie content is blurred out, required a paid tier in Patreon, etc. You can also add the URL link to the Patreon post, although it is probably easier to use the Patreon Posts page described below. For now, movie reactions that don't have a corresponding Patreon post won't have a direct link (i.e. directly to YouTube), but that will be added soon. To edit or delete the details of an existing reaction, click the vertical ellipsis and select the appropriate icon.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-managereactions.png'} alt={'Manage Reactions Help'} />
                </Box>
                <Typography variant={'h3'} sx={classes.sectionTitle}>
                    {'Patreon Posts (Reactors Only)'}
                </Typography>
                <Typography variant={'h6'}>
                    {`This page lists out all of your Patreon posts and lets you directly link them to a movie already in your reaction library (again, note this is NOT the same as your movie library on the Movies page). For now, you should add the movie(s) to your reaction library on the Manage Reactions page first before coming here to link them to a post. This will be streamlined in the future. A post can only be linked to a single version of a movie (i.e. full-length or edited) and vice versa. Use the toggle switch at the top to hide posts that have already been linked.`}
                </Typography>
                <Box sx={classes.imageBox}>
                    <img width={'70%'} loading={'lazy'} src={'i/help-patreonposts.png'} alt={'Patreon Posts Help'} />
                </Box>
            </Container>
        </Box>
    );
}