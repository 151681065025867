import * as actionTypes from './actionTypes';

export const actionCreators = {

    getMembershipCreatorRecords: () => dispatch => {
        dispatch({ type: actionTypes.getMembershipCreatorRecordsSaga });
    },

    refreshPatreonUserInfo: () => dispatch => {
        dispatch({ type: actionTypes.refreshPatreonUserInfoSaga });
    },

    refreshPatreonMemberships: () => dispatch => {
        dispatch({ type: actionTypes.refreshPatreonMembershipsSaga });
    },

    receiveMembershipCreatorRecords: memberships => dispatch => {
        dispatch({ type: actionTypes.receiveMembershipCreatorRecords, memberships });
    },

    getPatreonPosts: () => dispatch => {
        dispatch({ type: actionTypes.getPatreonPostsSaga });
    },

    getPatreonPostsForRnryUserId: rnryUserId => dispatch => {
        dispatch({ type: actionTypes.getPatreonPostsForRnryUserIdSaga, rnryUserId });
    },

    getPatreonPostsForTnsUserId: tnsUserId => dispatch => {
        dispatch({ type: actionTypes.getPatreonPostsForTnsUserIdSaga, tnsUserId });
    },

    receivePatreonPosts: posts => dispatch => {
        dispatch({ type: actionTypes.receivePatreonPosts, posts });
    },

    setIsLoadingPatreonPosts: isLoading => dispatch => {
        dispatch({ type: actionTypes.setIsLoadingPatreonPosts, isLoading });
    }
}