import React, { useCallback, useState, useEffect } from 'react';
import * as authStore from '../../r/authStore';
import * as rnryStore from '../../r/rnryStore';
import { useSelector } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';

export const AuthUserProfileConfirmation = () => {

    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const hasCheckedUserInfo = useSelector(state => authStore.selectors.selectHasCheckedUserInfo(state));
    const userFirstName = useSelector(state => authStore.selectors.selectUserFirstName(state));
    const userLastName = useSelector(state => authStore.selectors.selectUserLastName(state));
    const userEmail = useSelector(state => authStore.selectors.selectUserEmail(state));
    const isProfileConfirmed = useSelector(state => authStore.selectors.selectIsProfileConfirmed(state));
    const userId = useSelector(state => authStore.selectors.selectTnsUserId(state));

    const isConfirmProfileOpen = useSelector(state => rnryStore.selectors.selectIsConfirmProfileOpen(state));
    const setIsConfirmProfileOpen = useCallback(isOpen => dispatch(rnryStore.actionCreators.setIsConfirmProfileOpen(isOpen)), [dispatch]);

    const updateUserProfile = useCallback(record => dispatch(authStore.actionCreators.updateAuthUserInfo(record)), [dispatch]);

    const [canConfirm, setCanConfirm] = useState(false);

    const [tempFirstName, setTempFirstName] = useState(userFirstName);
    const [tempFirstNameError, setTempFirstNameError] = useState(false);
    const [tempFirstNameErrorMessage, setTempFirstNameErrorMessage] = useState('');

    const [tempLastName, setTempLastName] = useState(userLastName);
    const [tempLastNameError, setTempLastNameError] = useState(false);
    const [tempLastNameErrorMessage, setTempLastNameErrorMessage] = useState('');

    const [tempEmail, setTempEmail] = useState(userEmail);
    const [tempEmailError, setTempEmailError] = useState(false);
    const [tempEmailErrorMessage, setTempEmailErrorMessage] = useState('');

    useEffect(() => {
        setTempFirstName(userFirstName);
    }, [userFirstName]);

    useEffect(() => {
        setTempLastName(userLastName);
    }, [userLastName]);

    useEffect(() => {
        setTempEmail(userEmail);
    }, [userEmail]);

    useEffect(() => {
        if (isLoggedIn && hasCheckedUserInfo && !isProfileConfirmed) {
            setIsConfirmProfileOpen(true);
        }
    }, [isLoggedIn, hasCheckedUserInfo, isProfileConfirmed, setIsConfirmProfileOpen]);

    const handleConfirm = () => {
        updateUserProfile({
            tnsUserId: userId,
            firstName: tempFirstName,
            lastName: tempLastName,
            email: tempEmail,
            isProfileConfirmed: true
        });
        setIsConfirmProfileOpen(false);
    }

    const handleChangeFirstName = e => {
        setTempFirstName(e.target.value)
        if (e.target.value.trim().length < 2) {
            setTempFirstNameError(true);
            setTempFirstNameErrorMessage('First name is required')
        } else {
            setTempFirstNameError(false);
            setTempFirstNameErrorMessage('')
        }
    }

    const handleChangeLastName = e => {
        setTempLastName(e.target.value)
        if (e.target.value.trim().length < 2) {
            setTempLastNameError(true);
            setTempLastNameErrorMessage('Last name is required')
        } else {
            setTempLastNameError(false);
            setTempLastNameErrorMessage('')
        }
    }

    const handleChangeEmail = e => {
        setTempEmail(e.target.value)
        const newText = e.target.value.trim();
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
        if (newText.length < 2) {
            setTempEmailError(true);
            setTempEmailErrorMessage('Email is required')
        } else if (!regex.test(newText)) {
            setTempEmailError(true);
            setTempEmailErrorMessage('Invalid email address')
        } else {
            setTempEmailError(false);
            setTempEmailErrorMessage('')
        }
    }

    useEffect(() => {
        setCanConfirm(!tempEmailError && !tempFirstNameError && !tempLastNameError)
    }, [tempEmailError, tempFirstNameError, tempLastNameError]);

    return (
        <Dialog open={isConfirmProfileOpen} fullWidth>
            <DialogTitle>{'Profile'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{'Please confirm your profile information'}</DialogContentText>
                <Box>
                    <TextField
                        autoFocus
                        autoComplete={'off'}
                        margin={'normal'}
                        id={'firstName'}
                        label={'First Name'}
                        type={'text'}
                        fullWidth
                        variant={'standard'}
                        onChange={handleChangeFirstName}
                        value={tempFirstName}
                        error={tempFirstNameError}
                        helperText={tempFirstNameErrorMessage}
                    />
                    <TextField
                        autoFocus
                        margin={'normal'}
                        autoComplete={'off'}
                        id={'lastName'}
                        label={'Last Name'}
                        type={'text'}
                        fullWidth
                        variant={'standard'}
                        onChange={handleChangeLastName}
                        value={tempLastName}
                        error={tempLastNameError}
                        helperText={tempLastNameErrorMessage}
                    />
                    <TextField
                        autoFocus
                        margin={'normal'}
                        autoComplete={'off'}
                        id={'email'}
                        label={'Email'}
                        type={'email'}
                        fullWidth
                        variant={'standard'}
                        onChange={handleChangeEmail}
                        value={tempEmail}
                        error={tempEmailError}
                        helperText={tempEmailErrorMessage}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleConfirm} disabled={!canConfirm}>
                    {'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
