import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export const SearchBoxHeader = ({ searchText, setSearchText, minLength = 2, sx }) => {

    const [searchTextInternal, setSearchTextInternal] = useState('');
    const [isFiltered, setIsFiltered] = useState(false);

    const resetSearchText = useCallback(() => {
        setSearchText('');
        setSearchTextInternal('');
        setIsFiltered(false);
    }, [setSearchText]);

    const commitSearchText = useCallback(() => {
        if (searchTextInternal.trim().length >= minLength) {
            setSearchText(searchTextInternal);
            setIsFiltered(true);
        }
    }, [minLength, searchTextInternal, setSearchText]);

    const handleKeyDown = useCallback(e => {
        if (e.key === 'Enter') {
            if (searchTextInternal.trim().length >= minLength) {
                commitSearchText();
            }
            if (searchTextInternal.trim().length === 0) {
                resetSearchText();
            }
        }
    }, [searchTextInternal, minLength, commitSearchText, resetSearchText]);
    
    return (
        <Stack direction={'row'} m='10px' sx={sx}>
            <TextField autoFocus autoComplete={'off'} onKeyDown={handleKeyDown} sx={{ flexGrow: 1, mr: 1 }} label={'Search'} variant={'outlined'} value={searchTextInternal} onChange={e => setSearchTextInternal(e.target.value)} />
            <Button disabled={searchTextInternal.trim().length < minLength} onClick={commitSearchText}>{'Search'}</Button>
            <Button disabled={!isFiltered} onClick={resetSearchText}>{'Reset'}</Button>
        </Stack>
    );
};