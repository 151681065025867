import React, { useCallback, useEffect } from 'react';
import * as authStore from '../../r/authStore';
import * as rnryStore from '../../r/rnryStore';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { AuthUserProfileConfirmation } from './AuthUserProfileConfirmation';
import { useInterval } from '../../h/useInterval';

const classes = {
    loginContainer: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        alignItems: 'center'
    },
    loginText: {
        marginBottom: '24px'
    },
    progress: {
        color: '#ffffff'
    },
    authUserPageContainer: {
        display: 'flex',
        flexGrow: '1'
    },
    loginButton: {
        color: 'white',
        backgroundColor: 'grey',
        '&:hover': {
            backgroundColor: 'black'
        }
    },
    userBox: {
        display: 'flex',
        alignItems: 'center'
    },
    mgmtSelect: {
        mr: 4,
        width: '200px'
    },
    managingOtherUser: {
        backgroundColor: '#c10000'
    }
};

export const AuthUserMenu = () => {

    const dispatch = useDispatch();

    const getAuthUserInfo = useCallback(() => dispatch(authStore.actionCreators.getAuthUserInfo()), [dispatch]);

    const rnryUserIdToManage = useSelector(state => rnryStore.selectors.selectReactorRnryUserIdToManage(state));
    const reactors = useSelector(state => rnryStore.selectors.selectReactorRecords(state));
    //const reactors = useSelector(state => rnryStore.selectors.selectReactorsWithTnsUserId(state));
    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const isAdminUser = useSelector(state => authStore.selectors.selectIsAdminUser(state));
    const hasCheckedUserInfo = useSelector(state => authStore.selectors.selectHasCheckedUserInfo(state));
    const userFirstName = useSelector(state => authStore.selectors.selectUserFirstName(state));
    const rnryUserId = useSelector(state => authStore.selectors.selectRnryUserId(state));
    const setReactorRnryUserIdToManage = useCallback(id => dispatch(rnryStore.actionCreators.setReactorRnryUserIdToManage(id)), [dispatch]);

    const pollLoginStatus = useCallback(() => {
        console.info(`Polling login status - current isLoggedIn: ${isLoggedIn}`);
        getAuthUserInfo();
    }, [getAuthUserInfo, isLoggedIn]);

    useInterval(pollLoginStatus, 300000, isLoggedIn, !isLoggedIn);

    const loadReactions = useCallback(() => dispatch(rnryStore.actionCreators.getReactorReactions(rnryUserIdToManage)), [dispatch, rnryUserIdToManage]);

    const handleSelectChange = e => {
        setReactorRnryUserIdToManage(e.target.value);
    }

    useEffect(() => {
        getAuthUserInfo();
    }, [getAuthUserInfo]);

    useEffect(() => {
        if (rnryUserIdToManage && rnryUserIdToManage !== '') {
            loadReactions();
        }
    }, [rnryUserIdToManage, loadReactions]);

    useEffect(() => {
        if (isLoggedIn && rnryUserId && rnryUserId !== '' && reactors && reactors.length > 0) {
            setReactorRnryUserIdToManage(rnryUserId);
        }
    }, [rnryUserId, isLoggedIn, setReactorRnryUserIdToManage, reactors]);

    const getBackgroundColor = () => {
        if (rnryUserId === rnryUserIdToManage) {
            return classes.mgmtSelect;
        } else {
            return { ...classes.mgmtSelect, ...classes.managingOtherUser }
        }
    }

    const getName = nameInput => {
        if (nameInput && nameInput.trim() !== '') {
            return nameInput;
        } else {
            return '<UNKNOWN>';
        }
    }

    return (
        <Box sx={classes.container}>
            <AuthUserProfileConfirmation />
            {hasCheckedUserInfo && isLoggedIn && (
                <Box sx={classes.userBox}>
                    {isAdminUser &&
                        <FormControl variant={'standard'} sx={getBackgroundColor()} size={'small'}>
                            <Select value={rnryUserIdToManage} onChange={handleSelectChange}>
                                {reactors.map(r =>
                                    <MenuItem key={r.rnryUserRecord.id} value={r.rnryUserRecord.id}>{getName(r.reactorNamePatreon)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }
                    <Typography>{`Welcome ${userFirstName}!`}</Typography>
                </Box>
            )}
            {!hasCheckedUserInfo && <CircularProgress sx={classes.progress} />}
        </Box>
    );
};
